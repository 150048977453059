import Handsontable from 'handsontable';

// tslint:disable-next-line:only-arrow-functions
const regexValidator = function (this: Handsontable.GridSettings, value: string, callback: (valid: boolean) => void) {
    if (this.allowEmpty && (value === null || value === '')) {
        callback(true);
    } else if (value === null || value === '') {
        callback(false);
    } else if (this.regex) {
        callback(this.regex.test(value));
    } else {
        callback(true);
    }
};

(Handsontable as any).validators.registerValidator('regex', regexValidator);
