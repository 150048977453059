import { ILayer, IService } from 'krite/lib/types';
import Krite from 'krite/lib/krite';
import { WMTSSource } from 'krite/lib/sources/wmts/source';
import { WMTSOptions } from 'krite/lib/sources/wmts/layer';
import { OWSSource } from 'krite/lib/sources/ows/source';
import {Layer, TileLayer} from 'leaflet';

export class LayerManager implements IService {
    krite!: Krite;

    currentBaseMap!: ILayer;
    currentOverlays = new Set<string>();

    overlays: Record<string, Layer> = {
        'Kadastrale kaart': new TileLayer.WMS(
            'https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0',
            {
                minZoom: 12,
                zIndex: 30,
                layers: 'KadastraleGrens,Bijpijling,Label,Bebouwingvlak',
                format: 'image/png',
                transparent: true,
            }
        ),
    };

    basemaps: Array<[string, string, string, Record<string, any>?]> = [
        ['BGT Achtergrond', 'pdok/bgtachtergrond', 'basemaps'],
        ['BGT Pastel', 'pdok/bgtpastel', 'basemaps'],
        [
            'Top10 25',
            'TOP10NL',
            'brt',
            {
                maxNativeZoom: 14,
            },
        ],
        ['Luchtfoto', 'pdok/luchtfoto_2022_hr', 'basemaps'],
    ];

    defaultMap = ['BRT Grijs', 'pdok/brtachtergrondkaartgrijs', 'basemaps'];

    added(krite: Krite) {
        this.krite = krite;

        this.bootstrap();
    }

    async bootstrap() {
        const layer = await this.krite
            .getSource<WMTSSource>(this.defaultMap[2])
            .getLayer(this.defaultMap[1], {
                maxZoom: 12,
            });

        this.krite.map.addLayer(layer);

        this.setBasemap(this.basemaps[0]);
    }

    async setBasemap(basemap: [string, string, string, Record<string, any>?]) {
        let layerOptions: WMTSOptions = {
            minZoom: 13,
            zIndex: 1,
        };

        if (basemap[3]) {
            layerOptions = {
                ...layerOptions,
                ...basemap[3],
            };
        }

        const layer = await this.krite
            .getSource<WMTSSource>(basemap[2])
            .getLayer(basemap[1], layerOptions);

        if (this.currentBaseMap) {
            this.krite.map.removeLayer(this.currentBaseMap);
        }

        this.krite.map.addLayer(layer);
        this.currentBaseMap = layer;
    }

    async addOverlay(overlay: string) {
        if (this.overlays[overlay]) {
            this.krite.map.leaflet.addLayer(this.overlays[overlay])
        }
        
        this.currentOverlays.add(overlay)
    }

    async removeOverlay(overlay: string) {
        if (
            this.overlays[overlay] &&
            this.krite.map.leaflet.hasLayer(this.overlays[overlay])
        ) {
            this.overlays[overlay].remove();
        }

        this.currentOverlays.delete(overlay);
    }
}
