import './hooks';
import { mode } from '@/settings';

import './style/style.scss';
import 'leaflet/dist/leaflet.css';
import 'handsontable/dist/handsontable.full.min.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import * as L from 'leaflet';
console.log(L);
import '@geoman-io/leaflet-geoman-free';

import Vue from 'vue';

import App from './app/app.vue';
import router from './router';
import store from './store/store';
import krite from './krite';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import './icons';
import 'bulmalite/lib/index';

import './helpers/validators';

import numbro from 'numbro';
import nl from 'numbro/dist/languages/nl-NL.min';

numbro.registerLanguage(nl);
numbro.setLanguage('nl-NL');

import KritePlugin from '@/krite/plugin';

Vue.use(KritePlugin);

Vue.config.productionTip = false;

if (mode === 'production') {
    Sentry.init({
        Vue,
        dsn: 'https://763ced18b9a248a0ac813eb4decc91e0@o224933.ingest.sentry.io/4504248551669760',
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });

    Vue.config.errorHandler = (err, vm, info) => {
        // tslint:disable-next-line:no-console
        console.trace(err);
        store.commit('addMessage', err.name + ': ' + err.message);
        Sentry.captureException(err);
    };
}

router.beforeEach((to, from, next) => {
    if (!store.getters.logged && to.name !== 'login') {
        next({
            name: 'login',
            query: {
                next: encodeURI(window.location.pathname),
            },
        });
    } else {
        next();
    }
});

(async () => {
    if (window.localStorage.getItem('token')) {
        store.commit('restoreToken');

        try {
            await store.dispatch('restore');
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.debug('No previous token');
        }
    }

    new Vue({
        router,
        store,
        krite,
        render: (h) => h(App),
    }).$mount('#app');
})();

console.log(process.env.VUE_APP_API);
