import {Component, Vue} from "vue-property-decorator";
import imFetch from "@/api/imFetch";
import {asDateTime} from '@/helpers/formatters'
import Pagination from "@/components/pagination/pagination.vue";

@Component({
    components: {
        Pagination,
    }
})
export default class Taken extends Vue {
    tasks = [];

    page = 1;
    next: null | number = null;
    previous: null | number = null;

    mounted() {
        this.loadData();
    }

    async loadData() {
        const response = await imFetch(`/uitwisseling/tasks/?page=${this.page}`)

        this.tasks = response.results;
        this.next = response.next;
        this.previous = response.previous;
    }

    asDateTime(date: any) {
        return asDateTime(date)
    }

    nextPage() {
        this.page += 1;
        this.loadData();
    }

    previousPage() {
        this.page -= 1;
        this.loadData();
    }
}
