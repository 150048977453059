import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import imFetch from '@/api/imFetch';

@Component
export default class Location extends Vue {
    @Prop()
    project: any;

    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    name: string = '';

    loading = false;
    pending = false;
    success = false;

    @Watch('project')
    projectChanged() {
        if (this.project.location_name) {
            this.name = this.project.location_name;
        } else {
            this.name = '';
        }
    }

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending,
            'is-success': this.success,
        };
    }

    get btnText() {
        if (this.project.location) {
            return 'Opslaan';
        } else {
            return 'Aanmaken';
        }
    }

    nameChanged() {
        this.pending = true;
        this.addPending('location');
    }

    mounted() {
        if (this.project.location_name) {
            this.name = this.project.location_name;
        }
    }

    async submit() {
        this.loading = true;
        this.success = false;

        try {
            await imFetch(`/bodeminformatie/projects/${this.project.id}/location/`, {
                method: 'put',
                body: JSON.stringify({
                    name: this.name,
                }),
            });

            this.success = true;
            this.pending = false;
            this.removePending('location');
        } catch (e) {
            this.addMessage(`Er is iets misgegaan bij het opslaan van deze Locatie.\n (${e.name}: ${e.message})`);

        } finally {
            this.loading = false;
        }
    }
}