import { Module } from 'vuex';
import imFetch from '@/api/imFetch';
import Vue from 'vue';
import { formMeta } from '@/settings';

const moduleState = {
    index: [] as string[],
    metas: {
        form: formMeta,
    } as { [index: string]: any[] },
};

export default {
    state: moduleState,
    getters: {
        hasMetaIndex: (state) => {
            return state.index.length > 0;
        },
        hasMeta: (state) => (name: string) => {
            return Boolean(state.metas[name]);
        },
    },
    mutations: {
        insertMeta(state, { name, contents }) {
            Vue.set(state.metas, name, contents);
        },
        insertIndex(state, names: string[]) {
            if (state.index.length > 0) {
                state.index.splice(0);
            }

            state.index.push(...names);
        },
    },
    actions: {
        async fetchMeta({ commit }, name: string) {
            const request = await imFetch(`/meta/${name}/`);

            commit('insertMeta', {
                name,
                contents: request,
            });
        },
        async replaceMeta({ commit }, { name, contents }) {
            const request = await imFetch(`/meta/${name}/`, {
                method: 'POST',
                body: JSON.stringify(contents),
            });

            commit('insertMeta', {
                name,
                contents: request,
            });
        },
        async fetchMetaIndex({ commit }) {
            const request = await imFetch(`/meta/index/`);

            commit('insertIndex', request);
        },
    },
} as Module<typeof moduleState, any>;



