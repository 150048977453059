import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import imFetch from '@/api/imFetch';
import numbro from 'numbro';

@Component
export default class Overview extends Vue {
    @Mutation
    addMessage: any;

    @Prop()
    project: any;

    data: any = null;

    loading = false;

    get geometry() {
        if (this.data && this.data.geometry) {
            return numbro(this.data.geometry).format('0,0.0') + ' m²';
        } else {
            return null;
        }
    }

    mounted() {
        this.loadData();
    }

    async loadData() {
        this.loading = true;

        try {
            this.data = await imFetch(`/bodeminformatie/projects/${this.project.id}/summary/`);
        } catch (e) {
            this.addMessage(`Error: Kon overzicht entiteiten niet ophalen. (${e.name}: ${e.message})`);
        } finally {
            this.loading = false;
        }
    }
}
