import {Component, Prop, Vue, Watch} from "vue-property-decorator";

import HotMeta from "@/components/hotMeta/hotMeta.vue";
import imFetch from "@/api/imFetch";
import {Mutation} from "vuex-class";

@Component({
    components: {
        HotMeta,
    },
})
export default class PotentialContaminants extends Vue {
    @Prop()
    project!: any;

    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    potentialContaminants: any = null;

    toAdd: any[] | null = null;
    pending: any = null;
    valid = true;

    loading = false;
    success = false;

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending && this.pending.total > 0,
            'is-success': this.success,
        };
    }

    get disabled() {
        if (this.valid && this.pending && this.pending.total > 0) {
            return false;
        } else {
            return true;
        }
    }

    @Watch('pending')
    pendingChanged(val: boolean) {
        if (val) {
            this.addPending('boreholes');
        } else {
            this.removePending('boreholes');
        }
    }

    mounted() {
        this.loadData();
    }

    async loadData() {
        this.potentialContaminants = await imFetch(`/bodeminformatie/projects/${this.project.id}/potential_contaminants/`)
    }

    async submit() {
        this.loading = true;
        this.success = false;

        try {
            for (const put of this.pending.put) {
                await imFetch(`/bodeminformatie/projects/${this.project.id}/potential_contaminants/${put.id}/`, {
                    method: 'put',
                    body: JSON.stringify(put),
                });
            }

            for (const del of this.pending.delete) {
                await imFetch(`/bodeminformatie/projects/${this.project.id}/potential_contaminants/${del}/`, {
                    method: 'delete',
                });
            }

            for (const post of this.pending.post) {
                await imFetch(`/bodeminformatie/projects/${this.project.id}/potential_contaminants/`, {
                    method: 'post',
                    body: JSON.stringify(post),
                });
            }

            await this.loadData();
            this.pending = null;
            this.success = true;
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het vesturen van één van de verontreinigingen,\
                 niet alle gegevens zijn opgeslagen! (${e.name}: ${e.message})`,
            );
        } finally {
            this.loading = false;
        }
    }

    validated(valid: boolean) {
        this.valid = valid;
    }
}
