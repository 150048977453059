import Handsontable from 'handsontable';

const endAboveStartWarning = document.createElement('div');
endAboveStartWarning.className = 'cell-warning';
endAboveStartWarning.textContent = '⚠️';
endAboveStartWarning.title = 'Onderkant hoger dan bovenkant';

const duplicatedSubstanceWarning = document.createElement('div');
duplicatedSubstanceWarning.className = 'cell-warning';
duplicatedSubstanceWarning.textContent = '⚠️';
duplicatedSubstanceWarning.title = 'Stof meerdere keren gebruikt';

export const mutatedRenderer = (base: Handsontable.renderers.Base) => {
    const newRenderer: Handsontable.renderers.Base = (
        hotInstance,
        td,
        row,
        column,
        prop,
        value,
        cellProperties
    ) => {
        const meta: any = hotInstance.getCellMeta(row, column);
        base(hotInstance, td, row, column, prop, value, cellProperties);

        if (meta.mutated) {
            td.style.backgroundColor = '#fff5dc';
        }

        if (meta.end_depth_above_start) {
            td.style.backgroundColor = '#ff9761';

            td.insertBefore(
                endAboveStartWarning.cloneNode(true),
                td.firstChild
            );
        }

        if (meta.duplicatedSubstance) {
            td.style.backgroundColor = '#ff9761';

            td.insertBefore(
                duplicatedSubstanceWarning.cloneNode(true),
                td.firstChild
            );
        }

        return td;
    };

    return newRenderer;
};

export const analyticRenderer = (base: Handsontable.renderers.Base) => {
    const newRenderer: Handsontable.renderers.Base = (
        hotInstance,
        td,
        row,
        column,
        prop,
        value,
        cellProperties
    ) => {
        const meta: any = hotInstance.getCellMeta(row, column);
        base(hotInstance, td, row, column, prop, value, cellProperties);

        if (meta.mutated && meta.id) {
            td.style.backgroundColor = '#fff5dc';
        } else if (meta.mutated && value) {
            td.style.backgroundColor = '#c7ffdf';
        }

        if (meta.end_depth_above_start) {
            td.insertBefore(
                endAboveStartWarning.cloneNode(true),
                td.firstChild
            );
        }

        return td;
    };

    return newRenderer;
};

/**
 * Renderer for fake table headers in the normal hot tablespace
 * @param hotInstance
 * @param td
 * @param row
 * @param column
 * @param prop
 * @param value
 * @param cellProperties
 */
export const fakeHeaderRenderer: Handsontable.renderers.Text = (
    hotInstance,
    td,
    row,
    column,
    prop,
    value,
    cellProperties
) => {
    Handsontable.renderers.TextRenderer(
        hotInstance,
        td,
        row,
        column,
        prop,
        value,
        cellProperties
    );

    td.style.backgroundColor = '#f0f0f0';

    return td;
};
