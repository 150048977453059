import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import imFetch from '@/api/imFetch';

import Hot from './hot/hot.vue';
import { Getter, Mutation } from 'vuex-class';

@Component({
    components: {
        Hot,
    },
})
export default class Layers extends Vue {
    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    @Prop()
    project: any;

    @Prop()
    boreholes!: any[];

    dependencies: any = null;

    @Getter
    hasMeta: any;

    layers: any = [];

    valid = true;

    pending = false;
    loading = false;
    success = false;

    @Watch('pending')
    pendingChanged(val: boolean) {
        if (val) {
            this.addPending('boreholes');
        } else {
            this.removePending('boreholes');
        }
    }

    get disabled() {
        if (this.valid && this.pending) {
            return false;
        } else {
            return true;
        }
    }

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending,
            'is-success': this.success && !this.pending,
        };
    }

    mounted() {
        this.loadBoreholes();
        this.loadLayers();
    }

    async loadBoreholes() {
        const boreholes = await imFetch(`/bodeminformatie/projects/${this.project.id}/boreholes/`);

        this.dependencies = {
            boreholes,
        };
    }

    async loadLayers() {
        this.layers = await imFetch(`/bodeminformatie/projects/${this.project.id}/layers/`);
    }

    async submit() {
        this.loading = true;
        this.success = false;

        try {
            let operations: any;
            const hot = this.$refs.hot;

            try {
                operations = (hot as any).emitOperations();
            } catch (e) {
                this.addMessage(
                    `Er is iets misgegaan bij het omzetten van de tabel in instructies,\
                 er zijn geen gegevens verstuurd! (${e.name}: ${e.message})`,
                );
            }

            for (const put of operations.put) {
                await imFetch(`/bodeminformatie/projects/${this.project.id}/layers/${put.id}/`, {
                    method: 'put',
                    body: JSON.stringify(put),
                });
            }

            for (const del of operations.delete) {
                await imFetch(`/bodeminformatie/projects/${this.project.id}/layers/${del}/`, {
                    method: 'delete',
                });
            }

            for (const post of operations.post) {
                await imFetch(`/bodeminformatie/projects/${this.project.id}/layers/`, {
                    method: 'post',
                    body: JSON.stringify(post),
                });
            }

            await this.loadLayers();

            (hot as any).clearPending();

            this.pending = false;
            this.success = true;
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het versturen van één van de lagen,\
                 niet alle gegevens zijn opgeslagen! (${e.name}: ${e.message})`,
            );
        } finally {
            this.loading = false;
        }
    }

    validated(valid: boolean) {
        this.valid = valid;
    }
}
