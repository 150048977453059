import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import api from './api';
import codes from './codes';
import messages from './messages';
import meta from './meta';
import pending from './pending';

export default new Vuex.Store({
  modules: {
    api,
    codes,
    messages,
    meta,
    pending,
  },
});
