import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';

import Start from './main/main.vue';

import { NavigationGuard, Route } from 'vue-router';
import { Mutation } from 'vuex-class';
import { Borehole } from '@/types/api';

interface IGuards {
    beforeRouteUpdate: NavigationGuard;
    beforeRouteLeave?: NavigationGuard;
}

@Component({
    components: {
        Start,
    },
})
export default class Project extends Vue implements IGuards {
    @Prop()
    id!: number;

    @Mutation
    clearPending: any;

    project = null;

    boreholes: any[] | null = null;

    @Watch('id')
    idChanged() {
        this.loadData();
    }

    mounted() {
        this.loadData();
    }

    async loadData() {
        this.project = await imFetch(`/bodeminformatie/projects/${this.id}/`);
        this.boreholes = await this.fetchBoreholes();
    }

    async fetchBoreholes() {
        return await imFetch(`/bodeminformatie/projects/${this.id}/boreholes/`);
    }

    updateProject(project: any) {
        this.project = project;
    }

    async updateBoreholes(boreholes?: Borehole[]) {
        if (boreholes) {
            this.boreholes = boreholes;
        } else {
            this.boreholes = await this.fetchBoreholes();
        }
    }

    beforeRouteUpdate(to: Route, from: Route, next: (to?: false) => void) {
        this.checkPending(next);
    }

    beforeRouteLeave(to: Route, from: Route, next: (to?: false) => void) {
        this.checkPending(next);
    }

    checkPending(next: (to?: false) => void) {
        if (this.$store.state.pending.pending.size > 0) {
            if (
                confirm(
                    'Nog niet alle wijzigingen zijn opgeslagen, weet je zeker dat je deze pagina wilt verlaten?'
                )
            ) {
                this.clearPending();
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    }
}
