import { Component, Vue } from 'vue-property-decorator';

import Add from './add/add.vue';
import Projects from './projects/projects.vue';

@Component({
    components: {
        Add,
        Projects,
    },
})
export default class Start extends Vue {
    
}
