import Krite from 'krite/lib/krite';
import { ILayer } from 'krite/lib/types';

import { GeoJSON, Polygon } from 'leaflet';
import Evented from 'krite/lib/util/evented';

export class ProjectLayer extends Evented implements ILayer {
    title = 'Project';
    name = 'Project';
    abstract = 'Projectlayer';
    preview = '';
    legend = '';
    bounds = undefined;

    leaflet = new GeoJSON(undefined, {
        onEachFeature: (feature, layer) => {
            layer.on('click', (event) => {
                this.emit('shapeClick', event.target);
            });
        },
        style: {
            weight: 2,
        },
    });

    geometry!: GeoJSON.Polygon[];

    krite!: Krite;

    /**
     *
     * @param initialGeometry Multipolygon in EPSG:28992
     */
    constructor(private initialGeometry?: GeoJSON.MultiPolygon) {
        super();
    }

    added(krite: Krite) {
        this.krite = krite;

        if (this.initialGeometry) {
            for (const polygon of this.initialGeometry.coordinates) {
                this.addPolygon(
                    this.krite.crs.geoTo({
                        type: 'Polygon',
                        coordinates: polygon,
                    })
                );
            }
        }
    }

    /**
     *
     * @param geometry new Polygon in WGS:84
     */
    addPolygon(geometry: GeoJSON.Polygon) {
        this.leaflet.addData(geometry);
    }

    removePolygon(polygon: Polygon) {
        this.leaflet.removeLayer(polygon);
    }

    /**
     * Emit a polygon in EPSG:28992
     */
    emitMultiPolygon(): GeoJSON.MultiPolygon | null {
        const featureCollection =
            this.leaflet.toGeoJSON() as GeoJSON.FeatureCollection<GeoJSON.Polygon>;

        if (
            featureCollection.features &&
            featureCollection.features.length > 0
        ) {
            const multiPolygon: GeoJSON.MultiPolygon = {
                type: 'MultiPolygon',
                coordinates: [],
            };

            for (const feature of featureCollection.features) {
                multiPolygon.coordinates.push(
                    this.krite.crs.geoFrom(feature.geometry).coordinates
                );
            }

            return multiPolygon;
        } else {
            return null;
        }
    }

    deleteGeometry() {
        this.leaflet.clearLayers();
    }
}
