import { Component, Vue, Prop } from 'vue-property-decorator';

// import Export from './export/export.vue';
import Dossier from './dossier/dossier.vue';
import General from './general/general.vue';
import Location from './location/location.vue';
import PotentialContaminants from "./potentialContaminants/potentialContaminants.vue";

@Component({
    components: {
        // Export,
        Dossier,
        General,
        Location,
        PotentialContaminants,
    },
})
export default class Main extends Vue {
    @Prop()
    project: any;

    updateProject(project: any) {
        this.$emit('updateProject', project);
    }
}
