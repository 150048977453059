import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';

import Hot from './hot/hot.vue';
import { Mutation } from 'vuex-class';

@Component({
    components: {
        Hot,
    },
})
export default class Analyses extends Vue {
    @Prop()
    project: any;

    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    pending = false;
    valid = true;
    loading = false;
    success = false;

    dependencies: any = null;

    volgorde: number | null = null;
    volgordeProp: number | null = null;
    volgordes: any[] = [];

    get disabled() {
        if (this.valid && this.pending) {
            return false;
        } else {
            return true;
        }
    }

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending,
            'is-success': this.success,
        };
    }

    @Watch('pending')
    pendingChanged(val: boolean) {
        if (val) {
            this.addPending('boreholes');
        } else {
            this.removePending('boreholes');
        }
    }

    mounted() {
        this.loadDependencies();
        this.loadVolgordes();
    }

    async loadDependencies() {
        try {
            const samples: any[] = [];
            const catalog: { [index: string]: any[] } = await imFetch(
                `/bodeminformatie/projects/${this.project.id}/samples_overview/`
            );

            for (const category in catalog) {
                if (catalog[category]) {
                    samples.push(...catalog[category]);
                }
            }

            this.dependencies = {
                samples,
            };
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het inladen van de monsters. (${e.name}: ${e.message})`
            );
        }
    }

    async loadVolgordes() {
        this.volgordes = await imFetch('/frontend/standaardvolgordes/');

        // Sorteren op volgorde.name
        this.volgordes.sort((a, b) => {
            const A = a.name.toUpperCase();
            const B = b.name.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }

            return 0;
        });
    }

    async submit() {
        this.loading = true;

        try {
            const hot: any = this.$refs.hot;
            const { operations, cells } = hot.emitOperations();

            const newResults = await imFetch(
                `/bodeminformatie/projects/${this.project.id}/insert_analyses/`,
                {
                    method: 'post',
                    body: JSON.stringify({
                        ...operations,
                    }),
                }
            );

            hot.operationsEmitted(newResults, cells);

            this.pending = false;
            this.success = true;
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het versturen van één van de resultaten,\
             niet alle gegevens zijn opgeslagen! (${e.name}: ${e.message})`
            );
        } finally {
            this.loading = false;
        }
    }

    addVolgorde(id: number | null) {
        if (id) {
            const hot = this.$refs.hot;

            (hot as any).addVolgorde(id);
        }
    }

    clean() {
        if (this.$refs.hot) {
            (this.$refs.hot as any).clean();
        }
    }

    validated(valid: boolean) {
        this.valid = valid;
    }
}
