import { Component, Vue, Prop } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';
import { Mutation } from 'vuex-class';

import moment from 'moment';

@Component
export default class Overview extends Vue {
    @Prop()
    overview!: any;

    @Mutation
    addMessage: any;

    get filteredOverview() {
        if (this.overview && this.overview.analysemonster) {
            return this.overview.analysemonster;
        } else {
            return [];
        }
    }

    formatDate(date: string) {
        if (date) {
            return moment(date).format('D-M-YYYY');
        } else {
            return '-';
        }
    }

    async del(id: number) {
        if (confirm('Weet je het zeker?')) {
            try {
                await imFetch(`/bodeminformatie/samples/${id}/`, {
                    method: 'delete',
                });

                this.$emit('mutation');
            } catch (e) {
                this.addMessage(`Kon het monster niet verwijderen. (${e.name}: ${e.message}`);
            }
        }
    }
}
