import Krite from 'krite/lib/krite';
import { ILayer } from 'krite/lib/types';

import {
    FeatureGroup,
    CircleMarker,
    Point,
    CircleMarkerOptions,
    Circle,
} from 'leaflet';
import { Borehole } from '@/types/api';

export class BoreholesLayer implements ILayer {
    title = 'Boreholes';
    name = 'boreholes';
    abstract = 'Boorpuntlaag';
    preview = '';
    legend = '';
    bounds = undefined;

    leaflet = new FeatureGroup();

    krite!: Krite;

    constructor(private boreholes: Borehole[]) {}

    added(krite: Krite) {
        this.krite = krite;

        this.renewBoreholes();
    }

    renewBoreholes(boreholes?: Borehole[]) {
        if (boreholes) {
            this.boreholes = boreholes;
        }

        this.leaflet.clearLayers();

        for (const borehole of this.boreholes) {
            if (borehole.geometry) {
                this.addBorehole(borehole);
            }
        }

        this.updateLabels();
    }

    replaceBorehole(borehole: Borehole) {
        this.leaflet.eachLayer((layer: any) => {
            if (layer.options._id === borehole.id) {
                this.leaflet.removeLayer(layer);
            }
        });

        this.addBorehole(borehole, '#e67e22');
    }

    addBorehole(borehole: Borehole, color = '#FF33EE') {
        new CircleMarker(
            this.krite.crs.pointTo(new Point(borehole.x, borehole.y)),
            {
                _id: borehole.id,
                _label: borehole.name,
                radius: 8,
                color: color,
                snapIgnore: true,
            }
        ).addTo(this.leaflet);
    }

    updateLabels() {
        this.leaflet.eachLayer((layer: any) => {
            if (!layer.getTooltip()) {
                layer.bindTooltip(layer.options._label);
            }
        });
    }
}
