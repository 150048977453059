import store from '@/store/store';
import {IMeta} from '@/types/meta';

import moment from 'moment';

import Handsontable from 'handsontable';

import {mutatedRenderer, fakeHeaderRenderer} from '@/helpers/renderers';

export {mutatedRenderer, fakeHeaderRenderer};

/**
 * Prepare value for Handsontable
 * @param value value from backend
 * @param meta metadata for field
 */
export const beforePush = (value: any, meta: IMeta, dependencies?: any) => {
    if (value === null || value === '') {
        return null;
    }

    switch (meta.fieldtype) {
        case 'code':
            return store.getters.pkValue(value, meta.relation);
        case 'dependency':
            if (!dependencies || !dependencies[meta.relation]) {
                throw new Error(
                    `Afhankelijkheid ${meta.relation} niet ingeladen.`
                );
            }

            for (const dep of dependencies[meta.relation]) {
                if (dep.id === value) {
                    return dep[meta.format];
                }
            }

            return null;
        case 'datetime':
            return moment(value).format('D-M-YYYY');
        case 'date':
            return moment(value).format('D-M-YYYY');
        case 'boolean':
            if (value === true) {
                return 'Ja';
            }
            if (value === false) {
                return 'Nee';
            }
            return null;
        default:
            return value;
    }
};

/**
 * Prepare value for api call
 * @param value data from Handsontable
 * @param meta metadata for field
 */
export const beforePut = (value: any, meta: IMeta, dependencies?: any) => {
    if (value === '' || value === null) {
        return null;
    }

    switch (meta.fieldtype) {
        case 'code':
            return store.getters.codePk(value, meta.relation);
        case 'dependency':
            if (!dependencies || !dependencies[meta.relation]) {
                throw new Error(
                    `Afhankelijkheid ${meta.relation} niet ingeladen.`
                );
            }

            for (const dep of dependencies[meta.relation]) {
                if (dep[meta.format] === value) {
                    return dep.id;
                }
            }

            return null;
        case 'datetime':
            return moment(`${value} 12:00`, 'D-M-YYYY HH:mm').toISOString();
        case 'date':
            return moment(value, 'D-M-YYYY').format('YYYY-MM-DD');
        case 'boolean':
            if (value === 'Ja') {
                return true;
            }

            if (value === 'Nee') {
                return false;
            }

            return null;
        default:
            return value;
    }
};

/**
 * Mapping between internal field descriptors and handsontable nomenclature
 */
export const typeMapping: any = {
    numeric: 'numeric',
};

/**
 * Map cell metadata to handsontable gridsettings
 * @param meta
 * @param dependencies
 */
export const metaMapping = (
    meta: IMeta,
    dependencies?: any,
    mutated?: boolean
): Handsontable.GridSettings => {
    switch (meta.fieldtype) {
        case 'code':
            return {
                type: 'autocomplete',
                strict: true,
                allowEmpty: !meta.required,
                trimDropdown: false,
                source: store.getters.flatCode(meta.relation),
                renderer:
                    mutatedRenderer(
                        Handsontable.renderers.AutocompleteRenderer
                    ),
            };
        case 'choice':
            return {
                type: 'autocomplete',
                allowEmpty: !meta.required,
                regex: meta.required ? /.+/ : undefined,
                validator: 'regex' as any,
                source: store.getters.flatChoice(meta.relation),
                renderer: mutatedRenderer(
                    Handsontable.renderers.AutocompleteRenderer
                ),
            };
        case 'dependency':
            if (!dependencies || !dependencies[meta.relation]) {
                throw new Error(
                    `Afhankelijkheid ${meta.relation} niet ingeladen.`
                );
            }

            const flattend: string[] = [];

            for (const rel of dependencies[meta.relation]) {
                flattend.push(rel[meta.format]);
            }

            return {
                type: 'autocomplete',
                strict: true,
                allowEmpty: !meta.required,
                trimDropdown: false,
                source: flattend,
                renderer: mutatedRenderer(
                    Handsontable.renderers.AutocompleteRenderer
                )
                ,
            };
        case 'date':
        case 'datetime':
            return {
                type: 'date',
                allowEmpty: !meta.required,
                dateFormat: 'D-M-YYYY',
                datePickerConfig: {
                    firstDay: 1,
                    i18n: {
                        previousMonth: 'Previous Month',
                        nextMonth: 'Next Month',
                        months: [
                            'Januari',
                            'Februari',
                            'Maart',
                            'April',
                            'Mei',
                            'Juni',
                            'July',
                            'Augustus',
                            'September',
                            'Oktober',
                            'November',
                            'December',
                        ],
                        weekdays: [
                            'Zondag',
                            'Maandag',
                            'Dinsdag',
                            'Woensdag',
                            'Donderdag',
                            'Vrijdag',
                            'Zaterdag',
                        ],
                        weekdaysShort: [
                            'Zo',
                            'Ma',
                            'Di',
                            'Wo',
                            'Do',
                            'Vrij',
                            'Za',
                        ],
                    },
                },
                renderer: mutatedRenderer(
                    Handsontable.renderers.AutocompleteRenderer
                ),
            } as any;
        case 'numeric':
            return {
                type: 'numeric',
                allowEmpty: !meta.required,
                numericFormat: {
                    pattern: '0.000',
                    culture: 'nl-NL',
                },
                renderer:
                    mutatedRenderer(Handsontable.renderers.NumericRenderer)
                ,
            };
        case 'boolean':
            return {
                type: 'autocomplete',
                strict: true,
                allowEmpty: !meta.required,
                trimDropdown: false,
                source: ['Ja', 'Nee'],
                renderer: mutatedRenderer(
                    Handsontable.renderers.AutocompleteRenderer
                ),
            };
        default:
            return {
                type: typeMapping[meta.fieldtype],
                allowEmpty: !meta.required,
                regex: meta.format ? new RegExp(meta.format) : undefined,
                validator: 'regex' as any,
                renderer: mutatedRenderer(
                    Handsontable.renderers.AutocompleteRenderer
                ),
            };
    }
};

/**
 * Convert an instance from an object to an flat array
 * @param instance
 * @param metaList
 * @param dependencies
 */
export const transformInstance = (
    instance: any,
    metaList: IMeta[],
    dependencies?: any
) => {
    let row: any[];

    if (instance.id) {
        row = [instance.id];
    } else {
        row = [null];
    }

    for (const meta of metaList) {
        if (instance[meta.name] !== undefined) {
            row.push(beforePush(instance[meta.name], meta, dependencies));
        }
    }

    return row;
};
