const dateTimeFormatter = new Intl.DateTimeFormat('nl-NL', {
    minute: 'numeric',
    hour: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
})
const timeFormatter = new Intl.DateTimeFormat('nl-NL', {
    minute: 'numeric',
    hour: 'numeric',
    second: 'numeric',
})

export const asDateTime = (date: any) => {
    return dateTimeFormatter.format(new Date(date));
}

export const asTime = (date: any) => {
    return timeFormatter.format(new Date(date));
}
