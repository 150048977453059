import Vue from 'vue';
import Router from 'vue-router';
import { RoutePropsFunction, Route } from 'vue-router/types/router';


import Container from '@/components/container/container.vue';
import Login from '@/views/login/login.vue';
import Start from '@/views/start/start.vue';

import Project from '@/views/project/project.vue';
import Main from '@/views/project/main/main.vue';
import Boreholes from '@/views/project/boreholes/boreholes.vue';
import Layers from '@/views/project/layers/layers.vue';
import Samples from '@/views/project/samples/samples.vue';
import Analyses from '@/views/project/analyses/analyses.vue';
import Geo from '@/views/project/geo/geo.vue';
import Exchange from '@/views/project/exchange/exchange.vue';
import Taken from '@/views/taken/taken.vue';
import Taak from '@/views/taken/taak/taak.vue';

// Mogelijk Async:?
import Forms from '@/views/forms/forms.vue';
import Standaardvolgordes from '@/views/standaardvolgordes/standaardvolgordes.vue';

Vue.use(Router);

const paramCaster: RoutePropsFunction = (route: Route) => {
    const props: any = {};

    for (const param in route.params) {
        if (route.params.hasOwnProperty(param)) {
            props[param] = parseInt(route.params[param], 10);
        }
    }

    return props;
};

export default new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/',
            component: Container,
            children: [
                {
                    path: '',
                    name: 'start',
                    component: Start,
                },
                {
                    path: 'project/:id/',
                    // name: 'project',
                    component: Project,
                    props: paramCaster,
                    children: [
                        {
                            path: '',
                            name: 'project',
                            component: Main,
                        },
                        {
                            path: 'boorpunten',
                            name: 'boreholes',
                            component: Boreholes,
                        },
                        {
                            path: 'trajecten',
                            name: 'layers',
                            component: Layers,
                        },
                        {
                            path: 'monsters',
                            name: 'samples',
                            component: Samples,
                        },
                        {
                            path: 'analyses',
                            name: 'analyses',
                            component: Analyses,
                        },
                        {
                            path: 'geo/',
                            name: 'project-geo',
                            component: Geo,
                        },
                        {
                            path: 'exchange/',
                            name: 'project-exchange',
                            component: Exchange,
                        },
                    ],
                },
                {
                    path: 'formulieren/:name?/',
                    name: 'forms',
                    component: Forms,
                    props: true,
                },
                {
                    path: 'standaardvolgordes/:id?/',
                    name: 'standaardvolgordes',
                    component: Standaardvolgordes,
                    props: paramCaster,
                },
                {
                    path: 'taken',
                    name: 'taken',
                    component: Taken,
                },
                {
                    path: 'taken/:id',
                    name: 'taak',
                    component: Taak,
                    props: paramCaster,
                },
            ],
        },
    ],
});
