import fetchBase from './fetchBase';

import store from '@/store/store';

export default async (url: string, init?: RequestInit, xcust: boolean = true) => {
    const response = await fetchBase(store.state.api.config.base + url, init, xcust);

    if (response.status === 204) {
        return new Promise<void>((resolve) => { resolve(); });

    } else if (response.status >= 400) {
        throw new Error(`Got status code ${response.status} from server`);
    } else {
        return response.json();
    }
};
