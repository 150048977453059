import { Component, Vue } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';
import { Mutation } from 'vuex-class';

@Component
export default class Import extends Vue {
    @Mutation
    addMessage: any;

    filename: string | null = null;

    loading = false;
    success = false;
    error: string | null = null;
    project: number | null = null;

    refresh = false;

    get btnClass() {
        return {
            'is-loading': this.loading,
        };
    }

    fileChanged(el: any) {
        if (el.target.files && el.target.files[0]) {
            this.filename = el.target.files[0].name;
        } else {
            this.filename = null;
        }
    }

    async submit() {
        this.loading = true;
        this.error = null;
        this.project = null;
        this.success = false;

        try {
            const el: any = this.$refs.fileinput;

            if (el) {
                const result = await imFetch(
                    `/uitwisseling/import/${this.filename}?fresh=${this.refresh}`,
                    {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/xml',
                        },
                        body: el.files[0],
                    }
                );

                if (result.error) {
                    this.error = result.error;
                } else if (result.id) {
                    this.$router.push({
                        name: 'taak',
                        params: {
                            id: result.id,
                        },
                    });
                } else {
                    this.error = 'Onbekende importfout';
                }

                this.success = true;
            }
        } catch (e) {
            this.addMessage(
                `Fout bij versturen xml!  (${e.name}: ${e.message})`
            );
        } finally {
            this.loading = false;
        }
    }
}
