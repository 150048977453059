export const formMeta = [
    {
        name: 'name',
        lable: 'Veld',
        fieldtype: 'text',
        required: true,
    },
    {
        name: 'lable',
        lable: 'Naam',
        fieldtype: 'text',
        required: true,
    },
    {
        name: 'fieldtype',
        lable: 'Type veld',
        fieldtype: 'dependency',
        format: 'name',
        relation: 'fieldType',
        required: true,
    },
    {
        name: 'relation',
        lable: 'Relatie',
        fieldtype: 'text',
    },
    {
        name: 'format',
        lable: 'Formaat',
        fieldtype: 'text',
    },
    {
        name: 'required',
        lable: 'Verplicht',
        fieldtype: 'boolean',
        required: true,
    },
];

export const mode = <'production' | 'test' | 'development'>(
    String(process.env.NODE_ENV)
);
