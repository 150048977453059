/**
 * Beheer communicatie met server
 */

import { Module } from 'vuex';

const moduleState = {
    config: {
        base: process.env.VUE_APP_API,
        token: null as string | null,
    },
    user: null as any,
};

const tokenName = (() => {
    if (window.location.host.indexOf('.') > 0) {
        return window.location.host.split('.')[0] + '_token';
    } else {
        return 'generic_token';
    }
})();

export default {
    state: moduleState,
    getters: {
        logged: (state) => {
            return Boolean(state.config.token);
        },
    },
    mutations: {
        setToken(state, token: string) {
            state.config.token = token;

            window.localStorage.setItem(tokenName, token);
        },
        clearToken(state) {
            state.config.token = null;

            if (window.localStorage.getItem(tokenName)) {
                window.localStorage.removeItem(tokenName);
            }
        },
        restoreToken(state) {
            const token = window.localStorage.getItem(tokenName);

            if (token) {
                state.config.token = token;
            }
        },
        setUser(state, user: any) {
            state.user = user;
        },
    },
    actions: {
        async login({ commit, dispatch, state }, { username, password }) {
            try {
                const request = await fetch(state.config.base + '/users/login/',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            username,
                            password,
                        }),
                    },
                );

                if (request.status >= 400 && request.status < 500) {
                    throw new Error('Ongeldige inloggegevens!');
                } else if (!request.ok) {
                    throw new Error('Er heeft een onbekende fout opgetreden!');
                }

                const response = await request.json();

                commit('setToken', response.token);

                await dispatch('restore');
            } catch (e) {
                throw new Error('Fout bij inloggen: ' + e.message);
            }
        },
        async logout({ commit }) {
            commit('clearToken');
        },
        async restore({ commit, state }) {
            try {
                const request = await fetch(state.config.base + '/users/me/',
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Token ' + state.config.token,
                            'Content-Type': 'application/json',
                        },
                    },
                );

                if (!request.ok) {
                    throw new Error('Could not restore user');
                } else {
                    commit('setUser', await request.json());
                }
            } catch (e) {
                commit('clearToken');

                throw new Error('Could not restore user');
            }
        },
    },
} as Module<typeof moduleState, any>;
