import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import HotTrans from '@/components/hotTrans/hotTrans.vue';
import imFetch from '@/api/imFetch';
import { Mutation } from 'vuex-class';

@Component({
    components: {
        HotTrans,
    },
})
export default class General extends Vue {
    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    @Prop()
    project: any;

    pending: any = null;

    valid = true;
    loading = false;
    success = false;

    @Watch('pending')
    pendingChanged(val: any) {
        if (val) {
            this.addPending('general');
        } else {
            this.removePending('general');
        }
    }

    get disabled() {
        if (this.pending) {
            return false;
        } else {
            return true;
        }
    }

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending,
            'is-success': this.success && !this.pending,
        };
    }

    async updateProject() {
        this.loading = true;
        this.success = false;

        try {
            const response = await imFetch(`/bodeminformatie/projects/${this.project.id}/`, {
                method: 'PUT',
                body: JSON.stringify(this.pending),
            });

            this.$emit('updateProject', response);

            this.success = true;
            this.pending = null;
        } catch (e) {
            this.addMessage(
                `Fout tijdens versturen project naar server, gegevens zijn niet opgeslagen! (${e.name}: ${e.message})`
            );
        } finally {
            this.loading = false;
        }
    }

    validated(valid: boolean) {
        this.valid = valid;
    }
}
