import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import Handsontable, { GridSettings } from 'handsontable';
import { Action, Getter } from 'vuex-class';
import {
    metaMapping,
    fakeHeaderRenderer,
    transformInstance,
    beforePut,
    beforePush,
} from '@/helpers/hot';
import { IMeta } from '@/types/meta';

@Component
export default class Hot extends Vue {
    /**
     * We expect this meta item to be ready before this component is loaded
     */
    @Prop()
    meta!: string;

    /**
     * Dependencies that can be used to fill a dropdown cell
     */
    @Prop()
    dependencies!: any[] | null;

    @Prop()
    instances!: any[];

    @Action
    fetchMeta: any;

    @Action
    fetchCode: any;

    @Getter
    hasCode: any;

    hot!: Handsontable;

    mutatedRows: number[] = [];

    mutatedCells: { [index: number]: Set<number> } = {};

    invalidCells: any[] = [];

    /**
     * Codes we'll always need for this particular table
     */
    additionalCodes = [
        'BodemlaagBodemkenmerken',
        'BodemlaagBodemkenmerkenGradatie',
    ];

    /**
     * Definition of the addtion columns
     */
    additionHeaders: { [index: string]: number } = {};

    additionBodyMeta = {
        fieldtype: 'code',
        name: 'na',
        format: '',
        lable: 'Toevoeging',
        relation: 'BodemlaagBodemkenmerkenGradatie',
        required: false,
    };

    get metaList(): any[] {
        return this.$store.state.meta.metas[this.meta];
    }

    get metaLength() {
        return this.metaList.length;
    }

    get ready() {
        if (this.metaList) {
            for (const item of this.metaList) {
                if (
                    item.fieldtype === 'code' &&
                    item.relation &&
                    !this.hasCode(item.relation)
                ) {
                    this.fetchCode(item.relation);
                    return false;
                }
            }

            for (const code of this.additionalCodes) {
                if (!this.hasCode(code)) {
                    this.fetchCode(code);
                    return false;
                }
            }

            return true;
        } else {
            return false;
        }
    }

    get boreholeCol() {
        if (this.metaList) {
            return (
                this.metaList.findIndex((value: IMeta) => {
                    return value.name === 'hoortBijMeetObject';
                }) + 1
            );
        }
    }

    get upperDepthCol() {
        if (this.metaList) {
            return (
                this.metaList.findIndex((value: IMeta) => {
                    return value.name === 'upperDepth';
                }) + 1
            );
        }
    }

    get lowerDepthCol() {
        if (this.metaList) {
            return (
                this.metaList.findIndex((value: IMeta) => {
                    return value.name === 'lowerDepth';
                }) + 1
            );
        }
    }

    @Watch('ready')
    readyChanged(val: boolean) {
        if (val) {
            this.initHot();
        }
    }

    @Watch('instances')
    instancesChanged() {
        if (this.ready) {
            this.initHot();
        }
    }

    mounted() {
        if (!this.metaList) {
            this.fetchMeta(this.meta);
        } else if (this.ready) {
            this.initHot();
        }
    }

    beforeDestroy() {
        this.destroy();
    }

    destroy() {
        if (this.hot) {
            this.hot.destroy();
        }
    }

    initHot() {
        this.destroy();

        this.invalidCells = [];

        const container: Element = this.$refs.container as Element;

        this.hot = new Handsontable(container, this.getSettings());

        this.initRows();
        this.initAdditions();
    }

    getSettings() {
        const fakeHeaders = ['Nr.'];

        fakeHeaders.push(
            ...this.metaList.map((value) => {
                return value.lable;
            })
        );

        return {
            fixedRowsTop: 1,
            colHeaders: false,
            minSpareCols: 1,
            minSpareRows: 1,
            cells: this.cells,
            data: [fakeHeaders, ...this.initRows()],
            afterChange: this.afterChange,
            afterValidate: this.afterValidate,
            beforeChange: this.beforeChange,
        } as Handsontable.DefaultSettings;
    }

    initRows() {
        const transformed: any[] = [];

        for (const instance of this.instances) {
            transformed.push(
                transformInstance(instance, this.metaList, this.dependencies)
            );
        }

        return transformed;
    }

    initAdditions() {
        const headers: string[] = [];
        const additionSpace: any[][] = [];

        // Collect addition headers
        for (const instance of this.instances) {
            if (instance.additions) {
                for (const addition of Object.keys(instance.additions)) {
                    if (!headers.includes(addition)) {
                        headers.push(addition);
                    }
                }
            }
        }

        additionSpace.push(headers);

        let currentRow: any[];

        // Transform addtions to rows
        for (const instance of this.instances) {
            if (instance.additions) {
                currentRow = [];

                for (const additionHeader of headers) {
                    if (instance.additions[additionHeader]) {
                        currentRow.push(
                            beforePush(
                                instance.additions[additionHeader],
                                this.additionBodyMeta
                            )
                        );
                    } else {
                        currentRow.push(null);
                    }
                }

                additionSpace.push(currentRow);
            } else {
                additionSpace.push([]);
            }
        }

        this.hot.populateFromArray(0, this.hot.countCols() - 1, additionSpace);
    }

    /**
     * Fill different slices of the table with the appropiate cell settings
     * @param row
     * @param column
     */
    cells(row?: number, column?: number): GridSettings {
        if (row !== undefined && column !== undefined) {
            const mutated = Boolean(
                this.mutatedCells[row] && this.mutatedCells[row].has(column)
            );

            // Regular part of the table?
            if (column <= this.metaLength) {
                // First row contains our fake headers
                if (row === 0) {
                    return {
                        renderer: fakeHeaderRenderer,
                        editor: false,
                    };
                } else if (column === 0) {
                    // ID column
                    return {
                        readOnly: true,
                    };
                } else {
                    // Shift columns left to account for ID column
                    return metaMapping(
                        this.metaList[column - 1],
                        this.dependencies
                    );
                }
            } else {
                // Header contains addition categories
                if (row === 0) {
                    return metaMapping({
                        fieldtype: 'code',
                        name: 'na',
                        format: '',
                        lable: 'Toevoeging',
                        relation: 'BodemlaagBodemkenmerken',
                        required: false,
                    });
                } else {
                    return metaMapping(
                        {
                            fieldtype: 'code',
                            name: 'na',
                            format: '',
                            lable: 'Toevoeging',
                            relation: 'BodemlaagBodemkenmerkenGradatie',
                            required: false,
                        },
                    );
                }
            }

            return {};
        } else {
            return {};
        }
    }

    isValid() {
        for (const [row, col] of this.invalidCells.entries()) {
            if (row > 0 && col && !this.isEmptyRowById(row) && col.length > 0) {
                return false;
            }
        }

        return true;
    }

    isEmptyRow(row: any[]) {
        return (
            row.slice(1).filter((value) => {
                return value !== '' && value !== null;
            }).length === 0
        );
    }

    isEmptyRowById(row: number) {
        const raw: any[] = this.hot.getDataAtRow(row);

        return this.isEmptyRow(raw);
    }

    afterValidate(isValid: boolean, value: any, row: number, col: number) {
        if (!isValid) {
            if (!this.invalidCells[row]) {
                this.invalidCells[row] = [];
            }

            if (!this.invalidCells[row].includes(col)) {
                this.invalidCells[row].push(col);
            }
        } else {
            if (
                this.invalidCells[row] &&
                this.invalidCells[row].includes(col)
            ) {
                this.invalidCells[row].splice(
                    this.invalidCells[row].indexOf(col),
                    1
                );
            }
        }
    }

    beforeChange(changes: Array<[number, number, any, any]>, source: string) {
        if (changes && source !== 'populateFromArray' && this.upperDepthCol) {
            for (const [row, col, oldVal, val] of changes) {
                const meta = this.hot.getCellMeta(row, col) as Record<
                    string,
                    string
                >;

                for (const key of Object.keys(meta)) {
                    this.hot.removeCellMeta(row, col, key);
                }

                // Check is lowerDepth > upperDepth
                if (
                    col === this.lowerDepthCol &&
                    typeof val === 'number' &&
                    row > 0
                ) {
                    const upperDepth = this.hot.getDataAtCell(
                        row,
                        this.upperDepthCol
                    );

                    if (upperDepth !== null && upperDepth > val) {
                        this.hot.setCellMeta(
                            row,
                            col,
                            'end_depth_above_start',
                            'true'
                        );
                    }
                }
            }
        }
    }

    afterChange(changes: Array<[number, number, any, any]>, source: string) {
        if (
            changes &&
            this.isValid() &&
            this.upperDepthCol &&
            this.lowerDepthCol
        ) {
            for (const [row, col, oldVal, val] of changes) {
                // Track addtion headers
                if (col > this.metaLength) {
                    // Addition header has changed
                    if (row === 0 && val !== this.additionHeaders[col]) {
                        this.additionHeaders[col] = val;
                    }
                }

                // Track user changes
                if (source !== 'populateFromArray') {
                    if (row > 0) {
                        this.cellMutated(row, col);
                    } else if (col > this.metaLength) {
                        // Addtion header changed
                        for (let i = 1; i < this.hot.countRows() - 1; i++) {
                            this.cellMutated(i, col);
                        }
                    }

                    // If we change the borehole on an existing row all cells are mutated
                    if (
                        col === this.boreholeCol &&
                        this.hot.getDataAtCell(row, 0)
                    ) {
                        for (let i = 2; i < this.hot.countCols() - 1; i++) {
                            this.cellMutated(row, i);
                        }
                    }

                    // Onderkant hierboven vullen met bovenkant huidige rij
                    if (col === this.upperDepthCol && val && row > 1) {
                        // Onderkant vorige is leeg?
                        if (
                            !this.hot.getDataAtCell(row - 1, this.lowerDepthCol)
                        ) {
                            this.hot.setDataAtCell(
                                row - 1,
                                this.lowerDepthCol,
                                val
                            );
                        }
                    }

                    // Set upperDepth with previous lowerDepth
                    if (col === this.boreholeCol && val && row > 0) {
                        if (
                            !this.hot.getDataAtCell(row, this.upperDepthCol) &&
                            this.hot.getDataAtCell(row, this.boreholeCol) ===
                                this.hot.getDataAtCell(
                                    row - 1,
                                    this.boreholeCol
                                )
                        ) {
                            this.hot.setDataAtCell(
                                row,
                                this.upperDepthCol,
                                this.hot.getDataAtCell(
                                    row - 1,
                                    this.lowerDepthCol
                                )
                            );
                        }
                    }

                    this.hot.render();
                    this.$emit('pending', true);
                    this.$emit('valid', true);
                }
            }
        } else {
            this.$emit('valid', false);
        }
    }

    cellMutated(row: number, col: number) {
        if (!this.mutatedCells[row]) {
            this.mutatedCells[row] = new Set<number>();
        }

        this.mutatedCells[row].add(col);
        this.hot.setCellMeta(row, col, 'mutated', 'true');
    }

    emitOperations() {
        const operations = {
            put: [] as any[],
            post: [] as any[],
            delete: [] as any[],
            total: 0,
        };

        let reconstructed: any;
        let row: number;
        let meta: IMeta;
        let nr: number | null;
        let borehole: number | null;

        for (const [rowStr, set] of Object.entries(this.mutatedCells)) {
            row = Number(rowStr);

            nr = this.hot.getDataAtCell(row, 0);
            borehole = this.hot.getDataAtCell(row, this.boreholeCol as number);

            // Layer has an ID but hasn't been assigned a borehole, we'll delete
            if (nr && !borehole) {
                operations.delete.push(nr);
            } else if (borehole) {
                // We'll reconstruct all changed cells in this row
                reconstructed = {};

                for (const col of set) {
                    // Normal table part or addtion
                    if (col <= this.metaLength) {
                        meta = this.metaList[col - 1];

                        reconstructed[meta.name] = beforePut(
                            this.hot.getDataAtCell(row, col),
                            meta,
                            this.dependencies
                        );
                    } else {
                        if (this.additionHeaders[col]) {
                            if (!reconstructed.additions) {
                                reconstructed.additions = {} as {
                                    [index: string]: number;
                                };
                            }

                            reconstructed.additions[this.additionHeaders[col]] =
                                beforePut(
                                    this.hot.getDataAtCell(row, col),
                                    this.additionBodyMeta
                                );
                        }
                    }
                }

                // If nr is set, we can update an existing layer
                if (nr) {
                    reconstructed.id = nr;
                    operations.put.push(reconstructed);
                } else {
                    operations.post.push(reconstructed);
                }
            }
        }

        return operations;
    }

    clearPending() {
        this.mutatedCells = {};
    }
}
