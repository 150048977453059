import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import imFetch from '@/api/imFetch';
import Hot from './hot/hot.vue';

import Overview from './overview/overview.vue';

@Component({
    components: {
        Hot,
        Overview,
    },
})
export default class Samples extends Vue {
    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    @Prop()
    project: any;

    @Prop()
    boreholes!: any[];

    loading = false;

    pending: any = null;
    valid = true;

    samples: any[] | null = null;
    overview: any = null;

    @Watch('pending')
    pendingChanged(val: boolean) {
        if (val) {
            this.addPending('boreholes');
        } else {
            this.removePending('boreholes');
        }
    }

    get disabled() {
        if (this.valid && this.pending && this.pending.total > 0) {
            return false;
        } else {
            return true;
        }
    }

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending && this.pending.total > 0,
        };
    }

    mounted() {
        this.loadData();
        this.loadOverview();
    }

    async loadData() {
        this.samples = await imFetch(
            `/bodeminformatie/projects/${this.project.id}/samples/`
        );
    }

    async loadOverview() {
        this.overview = await imFetch(
            `/bodeminformatie/projects/${this.project.id}/samples_overview/`
        );
    }

    async submit() {
        this.loading = true;

        try {
            for (const put of this.pending.put) {
                await imFetch(
                    `/bodeminformatie/projects/${this.project.id}/samples/${put.id}/`,
                    {
                        method: 'put',
                        body: JSON.stringify(put),
                    }
                );
            }

            for (const del of this.pending.delete) {
                await imFetch(
                    `/bodeminformatie/projects/${this.project.id}/samples/${del}/`,
                    {
                        method: 'delete',
                    }
                );
            }

            for (const post of this.pending.post) {
                await imFetch(
                    `/bodeminformatie/projects/${this.project.id}/samples/`,
                    {
                        method: 'post',
                        body: JSON.stringify(post),
                    }
                );
            }

            await this.loadData();
            await this.loadOverview();
            this.pending = null;
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het versturen van één van de monsters,\
                 niet alle gegevens zijn opgeslagen! (${e.name}: ${e.message})`
            );
        } finally {
            this.loading = false;
        }
    }

    validated(valid: boolean) {
        this.valid = valid;
    }

    mutated() {
        this.loadData();
        this.loadOverview();
    }
}
