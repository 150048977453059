import imFetch from '@/api/imFetch';
import store from '@/store/store';

export interface PendingBoreholes {
    put: any[];
    delete: any[];
    post: any[];
    total: number;
}

export const putPendingBoreholes = async (
    project: number,
    pending: PendingBoreholes
) => {
    try {
        for (const put of pending.put) {
            await imFetch(
                `/bodeminformatie/projects/${project}/boreholes/${put.id}/`,
                {
                    method: 'put',
                    body: JSON.stringify(put),
                }
            );
        }

        for (const del of pending.delete) {
            await imFetch(
                `/bodeminformatie/projects/${project}/boreholes/${del}/`,
                {
                    method: 'delete',
                }
            );
        }

        for (const post of pending.post) {
            await imFetch(`/bodeminformatie/projects/${project}/boreholes/`, {
                method: 'post',
                body: JSON.stringify(post),
            });
        }
    } catch (e) {
        throw new Error(
            'Er is iets misgegaan bij het versturen van een boorpunt'
        );
    }
};
