
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import HotMeta from '@/components/hotMeta/hotMeta.vue';
import { Action, Mutation, State } from 'vuex-class';
import imFetch from '@/api/imFetch';

@Component({
    components: {
        HotMeta,
    },
})
export default class NewFormEditor extends Vue {
    @Prop()
    name!: string;

    @Action
    fetchMeta: any;

    @Mutation
    addMessage: any;

    @State((state) => state.meta.metas)
    metas!: { [index: string]: any[] };

    loading = false;
    success = false;
    pending: any = null;

    dependencies = {
        fieldType: [
            {
                id: 'text',
                name: 'Tekst',
            },
            {
                id: 'numeric',
                name: 'Numeriek',
            },
            {
                id: 'datetime',
                name: 'Tijd/Datum',
            },
            {
                id: 'date',
                name: 'Datum',
            },
            {
                id: 'code',
                name: 'Code',
            },
            {
                id: 'boolean',
                name: 'Ja/Nee',
            },
            {
                id: 'choice',
                name: 'Keuze',
            },
            {
                id: 'dependency',
                name: 'Afhankelijkeheid',
            },
        ],
    };

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending,
            'is-success': this.success,
        };
    }

    get hasMeta() {
        return Boolean(this.metas[this.name]);
    }

    get instances() {
        if (this.hasMeta) {
            const data = [...this.metas[this.name]];

            return data;
        } else {
            return [];
        }
    }

    @Watch('name')
    nameChanged() {
        if (!this.hasMeta) {
            this.fetchMeta(this.name);
        }
    }

    mounted() {
        if (!this.hasMeta) {
            this.fetchMeta(this.name);
        }
    }

    async submit() {
        if (this.pending === null) {
            return;
        }

        this.loading = false;
        this.success = false;

        try {
            for (const put of this.pending.put) {
                await imFetch(`/meta/${this.name}/${put.id}/`, {
                    method: 'put',
                    body: JSON.stringify(put),
                });
            }

            for (const del of this.pending.delete) {
                await imFetch(`/meta/${this.name}/${del}/`, {
                    method: 'delete',
                });
            }

            for (const post of this.pending.post) {
                await imFetch(`/meta/${this.name}/`, {
                    method: 'post',
                    body: JSON.stringify(post),
                });
            }

            await this.fetchMeta(this.name);

            this.pending = false;
            this.success = true;
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het versturen van één van de velden,\
                 niet alle gegevens zijn opgeslagen! (${e.name}: ${e.message})`
            );
        } finally {
            this.loading = false;
        }
    }
}
