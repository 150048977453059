import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import Handsontable from 'handsontable';

@Component
export default class Hot extends Vue {
    hot: Handsontable | null = null;

    @Prop()
    settings!: Handsontable.DefaultSettings;

    @Prop()
    data: any;

    @Watch('data')
    dataChanged() {
        this.applySettings();
    }

    mounted() {
        if (this.settings && this.data) {
            this.applySettings();
        }
    }

    beforeDestroy() {
        this.destroy();
    }


    applySettings() {
        if (this.hot) {
            this.destroy();
        }

        const container: Element = this.$refs.container as Element;

        this.hot = new Handsontable(container, {
            ...this.settings,
            data: this.data ? [...this.data] : undefined,
        });

        this.hot.validateCells(() => {});
    }

    applyData() {
        if (this.hot) {
            this.hot.loadData(this.data);
        }
    }

    destroy() {
        if (this.hot) {
            this.hot.destroy();
        }
    }
}
