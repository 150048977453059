import {Component, Vue, Prop} from 'vue-property-decorator';
import imFetch from "@/api/imFetch";

@Component
export default class Export extends Vue {
    @Prop()
    project: any;

    loading = false;

    versie = '12';
    sender = false;
    dossier = false;
    multisurface = true;
    refresh = false;
    versionCodes = false;

    versies = [
        ['12.1.0', '12'],
        ['13.3.0', '13'],
        ['14.3.0', '14'],
    ];

    get exportUrl() {
        // tslint:disable-next-line:max-line-length
        return this.$store.state.api.config.base + `/uitwisseling/export/${this.project.id}.xml?version=${this.versie}&sender=${this.sender}&dossier=${this.dossier}&multisurface=${this.multisurface}&version_codes=${this.versionCodes}&refresh=${this.refresh}`;
    }

    async exportProject() {
        try {
            this.loading = true;

            const {id} = await imFetch(`/uitwisseling/export?project=${this.project.id}&version=${this.versie}&sender=${this.sender}&dossier=${this.dossier}&multisurface=${this.multisurface}&version_codes=${this.versionCodes}&refresh=${this.refresh}`)

            this.$router.push({
                name: 'taak',
                params: {
                    id,
                }
            })
        } catch (e) {
            throw new Error(String(e))
        } finally {
            this.loading = false;
        }
    }
}
