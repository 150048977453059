import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import imFetch from '@/api/imFetch';

@Component
export default class NewForm extends Vue {
    @Mutation
    addMessage: any;

    newName = '';

    loading = false;

    async submit() {
        this.loading = true;

        try {
            await imFetch('/frontend/standaardvolgordes/', {
                method: 'post',
                body: JSON.stringify({
                    name: this.newName,
                }),
            });

            this.$emit('new');
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het aanmaken van de nieuwe standaardvolgorde! (${e.name}: ${e.message})`,
            );
        } finally {
            this.loading = false;
        }
    }
}