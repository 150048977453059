import store from '@/store/store';

export default async (url: string, init?: RequestInit, xcust: boolean = true) => {
    let imHeaders: any;

    if (init && init.headers) {
        imHeaders = init.headers;
    } else {
        imHeaders = {
            'Content-Type': 'application/json',
        };
    }

    const imInit: RequestInit = {
        headers: imHeaders,
    };

    if (store.state.api.config.token) {
        imHeaders.Authorization = 'Token ' + store.state.api.config.token;
    }

    if (init) {
        Object.assign(init, imInit);
    } else {
        init = imInit;
    }

    return await fetch(url, init);
};
