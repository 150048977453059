import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation } from 'vuex-class';

import imFetch from '@/api/imFetch';

@Component
export default class Projects extends Vue {
    @Mutation
    addMessage: any;

    projects = [];

    page = 1;

    async mounted() {
        this.loadData();
    }

    async loadData() {
        this.projects = await imFetch(`/bodeminformatie/projects/?page=${this.page}`);
    }

    nextPage() {
        this.page += 1;
        this.loadData();
    }

    prevPage() {
        this.page -= 1;
        this.loadData();
    }

    async del(id: string) {
        try {
            if (confirm('Weet je het zeker?')) {
                await imFetch(`/bodeminformatie/projects/${id}/`, {
                    method: 'delete',
                });

                this.loadData();
            }
        } catch (e) {
            this.addMessage('Kan onderzoek niet verwijderen!');
        }
    }
}
