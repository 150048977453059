import Krite from 'krite/lib/krite';
import Rd from 'krite/lib/crs/28992';

import {LayerManager} from '@/services/layerManager';
import {MapService} from 'krite/lib/services/map';
import {PdokLocatieserverService} from 'krite/lib/services/pdokLocatieserver';

import {WMTSSource} from 'krite/lib/sources/wmts/source';
import {NLBasemapsSource} from 'krite/lib/sources';

import {DivIcon, Point} from 'leaflet';
import {OWSSource} from "krite/lib/sources/ows/source";

const krite = new Krite({
    crs: new Rd(),
    fetch: window.fetch.bind(window),
});

const map = krite.addService(
    'MapService',
    new MapService({
        leaflet: {
            minZoom: 3,
            maxZoom: 16,
            center: [52.156, 5.389],
            zoom: 3,
            preferCanvas: true,
            doubleClickZoom: true,
        },
        checkZoom: true,
        defaultMarker: new DivIcon({
            className: 'krite-map-marker',
            html: '<div class="krite-map-marker"><svg width="24px" height="24px" viewBox="0 0 24 24"><path d="M12,11.5C10.62,11.5 9.5,10.38 9.5,9C9.5,7.62 10.62,6.5 12,6.5C13.38,6.5 14.5,7.62 14.5,9C14.5,10.38 13.38,11.5 12,11.5M12,2C8.13,2 5,5.13 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9C19,5.13 15.87,2 12,2Z"/></svg></div>',
            iconAnchor: new Point(24, 44),
        }),
    })
);

map.leaflet.pm.setLang('nl');

krite.addSource(
    'brk',
    new OWSSource(
        'https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0',
        {wfs: false}
    )
);

krite.addSource('basemaps', new NLBasemapsSource());
krite.addSource(
    'brt',
    new WMTSSource('https://service.pdok.nl/brt/top10nl/wmts/v1_0')
);

krite.addService('LayerManager', new LayerManager());
krite.addService('PdokLocatieserverService', new PdokLocatieserverService());

export default krite;
