import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BoreholeHot from './boreholeHot.vue';

import numbro from 'numbro';

@Component({
    components: { BoreholeHot },
})
export default class Boreholes extends Vue {
    @Prop()
    project: any;

    @Prop()
    boreholes!: any[];

    selectedIndex: number = 0;

    drawing = false;
    pending = false;
    sequence = false;

    modal = false;

    get selected() {
        if (this.boreholes && this.boreholes[this.selectedIndex]) {
            return this.boreholes[this.selectedIndex];
        } else {
            return null;
        }
    }

    get xy() {
        if (this.selected) {
            if (this.selected.geometry) {
                return [
                    numbro(this.selected.geometry.coordinates[0]).format({
                        mantissa: 2,
                        thousandSeparated: true,
                    }),
                    numbro(this.selected.geometry.coordinates[1]).format({
                        mantissa: 2,
                        thousandSeparated: true,
                    }),
                ];
            }
        }

        return [null, null];
    }

    get hasPrev() {
        return Boolean(
            this.boreholes && this.boreholes[this.selectedIndex - 1]
        );
    }

    get hasNext() {
        return Boolean(
            this.boreholes && this.boreholes[this.selectedIndex + 1]
        );
    }

    @Watch('boreholes')
    boreholesChanged() {
        if (!this.selected) {
            this.selectedIndex = 0;
        }

        if (this.sequence) {
        }
    }

    formatXY(coordinate: number) {
        return numbro(coordinate).format({
            mantissa: 2,
            thousandSeparated: true,
        });
    }

    start() {
        this.sequence = true;
        this.place();
    }

    place() {
        this.drawing = true;

        this.$emit(
            'needMarker',
            this.selected.name,
            (feature: null | GeoJSON.Feature<GeoJSON.Point>) => {
                if (feature) {
                    if (!this.pending) {
                        this.pending = true;
                    }

                    this.$emit('updateBorehole', {
                        ...this.selected,
                        geometry: feature.geometry,
                        x: feature.geometry.coordinates[0],
                        y: feature.geometry.coordinates[1],
                    });

                    if (this.sequence && this.hasNext) {
                        this.selectedIndex += 1;
                        this.place();

                        return true;
                    } else {
                        this.sequence = false;
                        this.drawing = false;
                    }
                } else if (this.sequence) {
                    // User canceled, always stop sequential drawing
                    this.sequence = false;
                    this.drawing = false;
                } else {
                    this.drawing = false;
                }

                return false;
            }
        );
    }

    shiftIndex(offset: number) {
        this.selectedIndex += offset;
    }
}
