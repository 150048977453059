import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';
import imFetch from '@/api/imFetch';

@Component
export default class Dossier extends Vue {
    @Prop()
    project: any;

    @Getter
    hasCode: any;

    @Action
    fetchCode: any;

    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    dossier: any = null;
    bronhouder: number | null = null;

    loading = false;
    pending = false;
    success = false;

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending,
            'is-success': this.success,
        };
    }

    get btnText() {
        if (this.project.dossiers) {
            return 'Opslaan';
        } else {
            return 'Aanmaken';
        }
    }

    get bronhouders() {
        if (this.hasCode('Bronhouders')) {
            return this.$store.state.codes.codes.Bronhouders;
        } else {
            return null;
        }
    }

    @Watch('project')
    projectChanged() {
        this.getDossier();
    }

    // @Watch('bronhouder')
    bronhouderChanged(val: any) {
        this.pending = true;
        this.addPending('dossier');
    }

    mounted() {
        if (!this.hasCode('Bronhouders')) {
            this.fetchCode('Bronhouders');
        }

        this.getDossier();
    }

    async getDossier() {
        this.dossier = await imFetch(`/bodeminformatie/projects/${this.project.id}/dossier/`);
        this.bronhouder = await this.dossier.dataAdministrator;
    }

    async submit() {
        this.loading = true;
        this.success = false;

        try {
            await imFetch(`/bodeminformatie/projects/${this.project.id}/dossier/`, {
                method: 'put',
                body: JSON.stringify({
                    dataAdministrator: this.bronhouder,
                }),
            });

            this.success = true;
            this.pending = false;
            this.removePending('dossier');
        } catch (e) {
            this.addMessage(`Er is iets misgegaan bij het opslaan van dit Dossier.\n (${e.name}: ${e.message})`);

        } finally {
            this.loading = false;
        }
    }
}
