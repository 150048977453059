import { Component, Vue } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';

@Component
export default class Tray extends Vue {
    @State((state) => state.messages.messages)
    messages!: string[];

    @Mutation('discardMessage')
    discard: any;
}
