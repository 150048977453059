import { render, staticRenderFns } from "./editor.vue?vue&type=template&id=5feb7b83&"
import script from "./editor.ts?vue&type=script&lang=ts&"
export * from "./editor.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports