import { Component, Vue } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';

@Component
export default class Add extends Vue {
    name = '';

    loading = false;

    async submit() {
        this.loading = true;

        try {
            const request = await imFetch('/bodeminformatie/projects/', {
                method: 'post',
                body: JSON.stringify({
                    name: this.name,
                })
            })


            this.$router.push({
                name: 'project',
                params: {
                    id: request.id,
                }
            })
        } catch (e) {
            throw new Error('Aanmaken nieuw onderzoek mislukt');
        } finally {
            this.loading = false;
        }


    }
}