import { Component, Vue } from 'vue-property-decorator';
import { LayerManager } from '@/services/layerManager';
import { Layer } from 'leaflet';

@Component
export default class Layers extends Vue {
    currentBasemap = '';
    currentOverlays = new Set<string>();

    basemaps: Array<[string, string, string, Record<string, any>?]> = [];
    overlays: Record<string, Layer> = {};

    mounted() {
        const service = this.$krite.getService<LayerManager>('LayerManager');

        this.basemaps = service.basemaps;
        this.currentBasemap = service.basemaps[0][1];

        this.overlays = service.overlays;
    }

    setBasemap(basemap: [string, string, string, Record<string, any>?]) {
        const service = this.$krite.getService<LayerManager>('LayerManager');

        service.setBasemap(basemap);
        this.currentBasemap = basemap[1];
    }

    toggleOverlay(overlay: string) {
        const service = this.$krite.getService<LayerManager>('LayerManager');

        if (this.currentOverlays.has(overlay)) {
            service.removeOverlay(overlay);
            this.currentOverlays.delete(overlay);
        } else {
            service.addOverlay(overlay);
            this.currentOverlays.add(overlay);
        }
    }
}
