import { Module } from 'vuex';
import imFetch from '@/api/imFetch';
import Vue from 'vue';


const moduleState = {
    messages: [] as string[],
    discarded: [] as string[],
};

export default {
    state: moduleState,
    getters: {
    },
    mutations: {
        addMessage(state, message: string) {
            state.messages.push(message);
        },
        discardMessage(state, index: number) {
            state.discarded.push(
                state.messages.splice(index, 1).pop() as string,
            );
        },
    },
} as Module<typeof moduleState, any>;
