
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Pagination extends Vue {
    @Prop()
    next!: number | null;

    @Prop()
    previous!: number | null;
}
