import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
// import { HotTable } from '@handsontable/vue';
import { Getter, Action, State } from 'vuex-class';

import Hot from '@/components/hot/hot.vue';

import { beforePush, beforePut, metaMapping } from '@/helpers/hot';

import { IMeta } from '@/types/meta';

@Component({
    components: {
        Hot,
        // HotTable,
    },
})
export default class HotTrans extends Vue {
    @Prop()
    value: any;

    @Prop()
    instance!: { [index: string]: any };

    @Prop()
    meta!: string;

    @Prop()
    toAdd!: any[] | null;

    @Action
    fetchMeta: any;

    @Action
    fetchCode: any;

    @Action
    fetchChoice: any;

    @Getter
    hasCode: any;

    @Getter
    hasChoice: any;

    @Getter
    flatCode: any;

    invalidRows = new Set<number>();

    typeMapping: any = {
        code: 'numeric',
        boolean: 'checkbox',
    };

    settings: Handsontable.DefaultSettings = {
        colHeaders: [
            'Attribuut',
            'Waarde',
        ],
        columns: [
            {
                readOnly: true,
            },
            {
            },
        ],
        stretchH: 'last',
        afterChange: this.afterChange,
        afterValidate: this.afterValidate,
        cells: this.cells,
    };

    tableData: Array<[string, any]> = [];

    get metaList() {
        return this.$store.state.meta.metas[this.meta];
    }

    get ready() {
        if (this.metaList) {
            for (const item of this.metaList) {
                if (item.fieldtype === 'code' && item.relation && !this.hasCode(item.relation)) {
                    this.fetchCode(item.relation);
                    return false;
                }

                if (item.fieldtype === 'choice' && item.relation && !this.hasChoice(item.relation)) {
                    this.fetchChoice(item.relation);
                    return false;
                }
            }

            return true;
        } else {
            return false;
        }
    }

    @Watch('ready')
    metaChanged() {
        if (this.ready) {
            this.buildForm();
        }
    }

    @Watch('instance')
    instanceChanged() {
        if (this.ready) {
            this.buildForm();
        }
    }

    mounted() {
        if (!this.metaList) {
            // No metadata has been loaded for this object type
            this.fetchMeta(this.meta);
        } else {
            // Metadata is present, we can check if the form is ready to be displayed
            this.metaChanged();
        }
    }

    cells(row: number | undefined, column: number | undefined) {
        if (row !== undefined && column !== undefined && column === 1) {
            const meta = this.metaList[row];

            return metaMapping(meta);
        } else {
            return {};
        }
    }

    buildForm() {
        if (this.metaList) {
            if (this.tableData.length > 0) {
                this.tableData.splice(0);
            }

            for (const meta of this.metaList) {
                this.tableData.push(
                    [
                        meta.lable as string,
                        beforePush(this.instance[meta.name], meta),
                    ],
                );
            }
        }
    }

    afterChange(changes: Array<[number, number | string, any, any]>, source: string) {
        if (changes) {
            const value = {
                ...this.value,
            };

            for (const [row, col, oldVal, val] of changes) {
                if (!this.invalidRows.has(row)) {
                    value[this.metaList[row].name] = beforePut(val, this.metaList[row]);
                }
            }

            this.$emit('input', value);
            this.$emit('valid', this.invalidRows.size === 0);
        }
    }

    afterValidate(isValid: boolean, value: any, row: number) {
        if (!isValid) {
            this.invalidRows.add(row);
        } else {
            this.invalidRows.delete(row);
        }
    }
}
