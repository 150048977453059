
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';
import { asDateTime, asTime } from '@/helpers/formatters';

@Component
export default class Taak extends Vue {
    @Prop()
    id!: number;

    finishedStates = ['SUCCESS', 'FAILURE', 'REVOKED'];
    refreshInterval = 2000;
    interval: number | null = null;

    task: any = null;

    mounted() {
        this.startRefreshInterval();
    }

    beforeDestroy() {
        this.clearInterval();
    }

    @Watch('id')
    idChanged() {
        this.clearInterval();
        this.startRefreshInterval();
    }

    clearInterval() {
        if (this.interval) {
            window.clearInterval(this.interval);
            this.interval = null;
        }
    }

    async getData() {
        this.task = await imFetch(`/uitwisseling/tasks/${this.id}`);

        if (this.finishedStates.includes(this.task.status)) {
            this.clearInterval();
        }
    }

    async getFile() {
        window.location.assign(
            this.$store.state.api.config.base +
                `/uitwisseling/tasks/${this.id}/file`
        );
    }

    startRefreshInterval() {
        this.interval = window.setInterval(this.getData, this.refreshInterval);
        this.getData();
    }

    asDateTime(date: string) {
        return asDateTime(date);
    }

    asTime(date: string) {
        return asTime(date);
    }

    get sortedMessages() {
        if (this.task) {
            return [...this.task.messages].sort((a, b) => {
                return a.id - b.id;
            });
        } else {
            return [];
        }
    }
}
