import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AddHelper extends Vue {
    prefix = '';
    start = '1';
    end: null | string = null;
    fill: null | string = null;

    get valid() {
        return this.start && this.end;
    }

    submit() {
        if (this.end) {
            const pending: any[] = [];
            const start = parseInt(this.start, 10);
            const end = parseInt(this.end, 10);
            const fill = this.fill ? parseInt(this.fill, 10) : 0;

            for (let i = start; i <= end; i++) {
                pending.push({
                    name: this.prefix + String(i).padStart(fill, '0'),
                    measurementObjectType: 1,
                });
            }

            this.$emit('input', pending);
        }
    }
}
