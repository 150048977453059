import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';

import HotMeta from '@/components/hotMeta/hotMeta.vue';
import { Mutation } from 'vuex-class';

@Component({
    components: {
        HotMeta,
    },
})
export default class Editor extends Vue {
    @Mutation
    addMessage: any;

    @Prop()
    id!: number;

    items: any[] | null = null;

    valid = false;
    pending: any = null;
    success = false;
    loading = false;

    get disabled() {
        if (this.valid && this.pending && this.pending.total > 0) {
            return false;
        } else {
            return true;
        }
    }

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending && this.pending.total > 0,
            'is-success': this.success,
        };
    }

    @Watch('id')
    idChanged() {
        this.loadData();
    }

    mounted() {
        this.loadData();
    }

    async loadData() {
        this.items = await imFetch(`/frontend/standaardvolgordes/${this.id}/items/`);
    }

    async submit() {
        this.loading = true;
        this.success = false;

        try {
            for (const put of this.pending.put) {
                await imFetch(`/frontend/standaardvolgordes/${this.id}/items/${put.id}/`, {
                    method: 'put',
                    body: JSON.stringify(put),
                });
            }

            for (const del of this.pending.delete) {
                await imFetch(`/frontend/standaardvolgordes/${this.id}/items/${del}/`, {
                    method: 'delete',
                });
            }

            for (const post of this.pending.post) {
                await imFetch(`/frontend/standaardvolgordes/${this.id}/items/`, {
                    method: 'post',
                    body: JSON.stringify(post),
                });
            }

            this.pending = null;
            this.success = true;
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het vesturen van één van de rijen,\
                 niet alle gegevens zijn opgeslagen! (${e.name}: ${e.message})`,
            );
        } finally {
            this.loading = false;
        }
    }

    async remove() {
        if (confirm('Weet je dit zeker?')) {
            try {
                await imFetch(`/frontend/standaardvolgordes/${this.id}/`, {
                    method: 'delete',
                });

                this.$emit('removed');

                this.$router.push({
                    name: 'standaardvolgordes',
                });
            } catch (e) {
                this.addMessage(
                    `Er is iets misgegaan bij het verwijderen van deze standaardvolgorde,\
                     (${e.name}: ${e.message})`,
                );
            }
        }
    }

    async rename() {
        const name = prompt('Geef een nieuwe naam op');

        if (name) {
            try {
                await imFetch(`/frontend/standaardvolgordes/${this.id}/`, {
                    method: 'put',
                    body: JSON.stringify({
                        name,
                    }),
                });

                this.$emit('removed');
            } catch (e) {
                this.addMessage(
                    `Kon de naam van de standaardvolgorde niet wijzigen,\
                     (${e.name}: ${e.message})`,
                );
            }
        }
    }


    validated(valid: boolean) {
        this.valid = valid;
    }
}
