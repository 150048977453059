import { Module } from 'vuex';

const moduleState = {
    pending: new Set<string>(),
};

export default {
    state: moduleState,
    getters: {
    },
    mutations: {
        addPending(state, name) {
            state.pending.add(name);
        },
        removePending(state, name) {
            state.pending.delete(name);
        },
        clearPending(state) {
            state.pending.clear();
        },
    },
    actions: {
    },
} as Module<typeof moduleState, any>;



