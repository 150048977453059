import { Component, Vue } from 'vue-property-decorator';

import { Action } from 'vuex-class';

@Component
export default class Login extends Vue {
    @Action
    login: any;

    username: string = '';
    password: string = '';

    loading = false;
    error: null | string = null;

    async doLogin() {
        this.loading = true;
        this.error = null;

        try {
            await this.login({
                username: this.username,
                password: this.password,
            });

            if (this.$route.query.next) {
                this.$router.push(decodeURI(this.$route.query.next as string));
            } else {
                this.$router.push({
                    name: 'start',
                });
            }
        } catch (e) {
            this.error = e.message;
        } finally {
            this.loading = false;
        }
    }
}
