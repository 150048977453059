
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import HotMeta from '@/components/hotMeta/hotMeta.vue';
import imFetch from '@/api/imFetch';
import { Mutation } from 'vuex-class';
import { putPendingBoreholes } from '@/helpers/boreholes';
import pending from '@/store/pending';

@Component({
    components: { HotMeta },
})
export default class boreholeHot extends Vue {
    @Prop()
    boreholes!: any[];

    @Prop()
    project: any;

    @Mutation
    addMessage: any;

    copiedBoreholes: any[] = [];

    pending: any = null;
    loading = false;
    success = false;
    valid = true;

    @Watch('boreholes', { immediate: true })
    boreholesChanged() {
        this.copiedBoreholes = [...this.boreholes];
        this.pending = null;
    }

    get height() {
        return window.innerHeight * 0.6;
    }

    get settings() {
        return {
            height: this.height,
        };
    }

    get disabled() {
        if (this.valid && this.pending && this.pending.total > 0) {
            return false;
        } else {
            return true;
        }
    }

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending && this.pending.total > 0,
            'is-success': this.success,
        };
    }

    validated(valid: boolean) {
        this.valid = valid;
    }

    async submit() {
        if (!this.pending) {
            return;
        }

        this.loading = true;
        this.success = false;

        try {
            await putPendingBoreholes(this.project.id, this.pending);

            this.success = true;

            this.$emit('updateBoreholes');
            this.$emit('input', false);
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het versturen van één van de boorpunten,
                 niet alle gegevens zijn opgeslagen!`
            );
        } finally {
            this.loading = false;
        }
    }
}
