import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';

import HotMeta from '@/components/hotMeta/hotMeta.vue';
import addHelper from './addHelper/addHelper.vue';
import { Mutation } from 'vuex-class';
import { PendingBoreholes, putPendingBoreholes } from '@/helpers/boreholes';

@Component({
    components: {
        addHelper,
        HotMeta,
    },
})
export default class Boreholes extends Vue {
    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    @Prop()
    project: any;

    @Prop()
    boreholes: any;

    toAdd: any[] | null = null;
    pending: PendingBoreholes | null = null;
    valid = true;

    loading = false;
    success = false;

    @Watch('pending')
    pendingChanged(val: boolean) {
        if (val) {
            this.addPending('boreholes');
        } else {
            this.removePending('boreholes');
        }
    }

    @Watch('boreholes')
    boreholesChanged() {
        this.pending = null;
    }

    get disabled() {
        if (this.valid && this.pending && this.pending.total > 0) {
            return false;
        } else {
            return true;
        }
    }

    get btnClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.pending && this.pending.total > 0,
            'is-success': this.success,
        };
    }

    async loadData() {
        return await imFetch(
            `/bodeminformatie/projects/${this.project.id}/boreholes/`
        );
    }

    async submit() {
        if (!this.pending) {
            return;
        }

        this.loading = true;
        this.success = false;

        try {
            await putPendingBoreholes(this.project.id, this.pending);

            const boreholes = await this.loadData();
            this.success = true;

            this.$emit('updateBoreholes', boreholes);
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het versturen van één van de boorpunten,
                 niet alle gegevens zijn opgeslagen!`
            );
        } finally {
            this.loading = false;
        }
    }

    addHelperAdded(pending: any[]) {
        this.toAdd = pending;
    }

    validated(valid: boolean) {
        this.valid = valid;
    }
}
