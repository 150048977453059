import { Component, Vue, Prop } from 'vue-property-decorator';

import Export from './export/export.vue';
import Overview from './overview/overview.vue';

@Component({
    components: {
        Export,
        Overview,
    },
})
export default class Exchange extends Vue {
    @Prop()
    project: any;
}
