var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"section"},[(_vm.task)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-content"},[_c('table',{staticClass:"table is-fullwidth"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Naam")]),_c('td',[_vm._v(_vm._s(_vm.task.description))])]),_c('tr',[_c('th',[_vm._v("Status")]),_c('td',[_vm._v(_vm._s(_vm.task.status))])]),_c('tr',[_c('th',[_vm._v("Aangemaakt")]),_c('td',[_vm._v(_vm._s(_vm.asDateTime(_vm.task.timestamp)))])]),(_vm.task.project)?_c('tr',[_c('th',[_vm._v("Onderzoek")]),_c('td',[_c('router-link',{attrs:{"to":{
                                            name: 'project',
                                            params: { id: _vm.task.project },
                                        }}},[_vm._v("Onderzoek "+_vm._s(_vm.task.project)+" ")])],1)]):_vm._e()])])])]),_c('br'),(_vm.task.file)?_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-content"},[_c('button',{staticClass:"button is-fullwidth",on:{"click":_vm.getFile}},[_vm._v(" Bijlage downloaden ")])])]):_vm._e()]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-content"},[_c('table',{staticClass:"table is-fullwidth is-hoverable"},[_vm._m(3),_c('tbody',_vm._l((_vm.sortedMessages),function(bericht){return _c('tr',{key:bericht.id},[_c('td',[_vm._v(_vm._s(_vm.asTime(bericht.timestamp)))]),_c('td',{staticClass:"task-message"},[_vm._v(" "+_vm._s(bericht.message)+" ")])])}),0)])])])])]):_c('bl-loading')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Details")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Bijlage")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Berichten")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('th',[_vm._v("Tijd")]),_c('th',[_vm._v("Bericht")])])
}]

export { render, staticRenderFns }