import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, State } from 'vuex-class';

import NewFormEditor from '@/views/forms/newFormEditor.vue';

@Component({
    components: {
        NewFormEditor,
    },
})
export default class Forms extends Vue {
    @Prop()
    name!: string;

    @Getter
    hasMetaIndex!: boolean;

    @Action
    fetchMetaIndex: any;

    @State((state) => state.meta.index)
    index!: string[];

    mounted() {
        if (!this.hasMetaIndex) {
            this.fetchMetaIndex();
        }
    }
}
