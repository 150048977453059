import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';

import { BoreholesLayer } from '@/layers/boreholes';

import Boreholes from './boreholes/boreholes.vue';
import Layers from '@/components/layers/layers.vue';
import Leaflet from '@/components/leaflet/leaflet.vue';

import Pdok from '@/krite/search.vue';

import { Mutation } from 'vuex-class';
import { ProjectLayer } from '@/layers/project';

import { Polygon } from 'leaflet';
import { Borehole } from '@/types/api';

@Component({
    components: {
        Boreholes,
        Layers,
        Leaflet,
        Pdok,
    },
})
export default class Geo extends Vue {
    @Mutation
    addMessage: any;

    @Mutation
    addPending: any;

    @Mutation
    removePending: any;

    @Prop()
    project: any;

    @Prop()
    boreholes!: any[];

    boreholeLayer!: BoreholesLayer;
    projectLayer!: ProjectLayer;

    internalBoreholes: any[] = [];

    mode: string | null = null;
    editing = false;

    boreholesPending: any[] = [];

    loading = false;
    shapesDirty = false;
    saved = false;
    error = true;

    @Watch('boreholes', { immediate: true })
    boreHolesChanged() {
        this.internalBoreholes = [...this.boreholes];

        if (!this.boreholeLayer) {
            if (this.$krite.map.hasLayerByName('boreholes')) {
                this.$krite.map.removeLayer(
                    this.$krite.map.layerByName['boreholes']
                );
                console.info('Had to remove duplicate borehole layer');
            }

            const layer = (this.boreholeLayer = new BoreholesLayer(
                this.internalBoreholes
            ));

            this.$krite.map.addLayer(layer);
        } else {
            this.boreholeLayer.renewBoreholes(this.internalBoreholes);
        }
    }

    mounted() {
        this.setupMap();
    }

    beforeDestroy() {
        if (this.boreholeLayer) {
            this.$krite.map.removeLayer(this.boreholeLayer);
        }

        if (this.projectLayer) {
            this.$krite.map.removeLayer(this.projectLayer);
        }
    }

    shapeButtonClass(button: string) {
        if (button === this.mode) {
            return ['is-active'];
        }
    }

    get saveButtonClass() {
        return {
            'is-loading': this.loading,
            'is-warning': this.shapesDirty || this.boreholesPending.length > 0,
            'is-success': this.saved,
        };
    }

    async setupMap() {
        const projectLayer = (this.projectLayer = new ProjectLayer(
            this.project.geometry
        ));

        this.$krite.map.addLayer(projectLayer);

        if (this.project.geometry) {
            this.$krite.map.leaflet.fitBounds(projectLayer.leaflet.getBounds());
        }

        projectLayer.on('shapeClick', this.shapeClicked);
    }

    newShape() {
        this.mode = 'new';

        this.$krite.map.leaflet.pm.enableDraw('Polygon', {});

        this.$krite.map.leaflet.once('pm:create', (shape) => {
            const geojson = (shape.layer as Polygon).toGeoJSON();
            shape.layer.remove();

            if (geojson.geometry.type === 'Polygon') {
                this.projectLayer.addPolygon(geojson.geometry);
                this.shapesDirty = true;
                this.addPending('geo-shape');
            }

            this.mode = null;
        });
    }

    async editShape() {
        this.mode = 'edit';
        this.projectLayer.leaflet.pm.enable();
        this.shapesDirty = true;
        this.addPending('geo-shape');
    }

    async deleteShape() {
        this.mode = 'delete';
    }

    async cancelShape() {
        switch (this.mode) {
            case 'marker':
            case 'new':
                this.$krite.map.leaflet.pm.disableDraw();
                this.$krite.map.leaflet.off('pm:create');
                break;
            case 'edit':
                this.projectLayer.leaflet.pm.disable();
                this.$krite.map.leaflet.off('pm:change');
                break;
        }

        this.mode = null;
    }

    shapeClicked(layer: Polygon) {
        switch (this.mode) {
            case 'delete':
                this.projectLayer.removePolygon(layer);
                this.shapesDirty = true;
                this.addPending('geo-shape');
                this.mode = null;
                break;
            default:
                break;
        }
    }

    async submit() {
        this.loading = true;
        this.saved = false;

        try {
            if (this.shapesDirty) {
                await this.submitShape();
            }

            await this.submitBoreholes();

            this.saved = true;
        } catch (e) {
            this.error = true;
            this.addMessage(
                `Er is iets misgegaan bij het versturen de geometrie,\
                niet alle gegevens zijn opgeslagen! (${e.name}: ${e.message})`
            );
        } finally {
            this.loading = false;
        }
    }

    async submitShape() {
        try {
            const multiPolygon = this.projectLayer.emitMultiPolygon();

            const response = await imFetch(
                `/bodeminformatie/projects/${this.project.id}/`,
                {
                    method: 'put',
                    body: JSON.stringify({
                        geometry: multiPolygon,
                    }),
                }
            );

            this.$emit('updateProject', response);

            this.shapesDirty = false;
            this.removePending('geo-shape');
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het versturen van de veelhoek,\
                 geometrie is niet opgeslagen! (${e.name}: ${e.message})`
            );
        }
    }

    async submitBoreholes() {
        try {
            let borehole: any;

            while (this.boreholesPending.length > 0) {
                borehole = this.boreholesPending.pop();

                await imFetch(
                    `/bodeminformatie/projects/${this.project.id}/boreholes/${borehole.id}/`,
                    {
                        method: 'put',
                        body: JSON.stringify({
                            geometry: borehole.geometry,
                        }),
                    }
                );
            }

            this.$emit('updateBoreholes', this.internalBoreholes);
            this.removePending('geo-boreholes');
        } catch (e) {
            this.addMessage(
                `Er is iets misgegaan bij het versturen van één van de boorpunten,\
                niet alle geometrie is opgeslagen! (${e.name}: ${e.message})`
            );
        }
    }

    async newMarker(label: string, callback: (marker: any | null) => boolean) {
        let created = false;
        this.mode = 'marker';

        this.$krite.map.leaflet.pm.setLang(
            'customName' as any,
            {
                tooltips: {
                    placeCircleMarker: label,
                },
            },
            'nl'
        );

        this.$krite.map.leaflet.pm.enableDraw('CircleMarker', {
            snappable: false,
        });

        this.$krite.map.leaflet.once('pm:create', (shape: any) => {
            created = true;
            shape.layer.remove();

            if (!callback(this.$krite.crs.geoFrom(shape.marker.toGeoJSON()))) {
                this.cancelShape();
                this.boreholeLayer.updateLabels();
            }
        });

        this.$krite.map.leaflet.once('pm:drawend', () => {
            if (!created) {
                callback(null);
                this.cancelShape();
            }
        });
    }

    updateBorehole(borehole: Borehole) {
        if (this.internalBoreholes) {
            const index = this.internalBoreholes.findIndex((value: any) => {
                return value.id === borehole.id;
            });

            Vue.set(this.internalBoreholes, index, borehole);

            this.boreholesPending.push(borehole);

            this.boreholeLayer.replaceBorehole(borehole);

            this.addPending('geo-boreholes');
        }
    }
}
