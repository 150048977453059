import Vue from 'vue';

import Alert from 'vue-material-design-icons/Alert.vue';
import Account from 'vue-material-design-icons/Account.vue';
import Settings from 'vue-material-design-icons/Settings.vue';
import Magnify from 'vue-material-design-icons/Magnify.vue';

Vue.component('mdi-alert', Alert);
Vue.component('mdi-account', Account);
Vue.component('mdi-settings', Settings);
Vue.component('mdi-magnify', Magnify);


import Cancel from 'vue-material-design-icons/Cancel.vue';
import Check from 'vue-material-design-icons/Check.vue';
import Delete from 'vue-material-design-icons/Delete.vue';
import Plus from 'vue-material-design-icons/Plus.vue';
import Pencil from 'vue-material-design-icons/Pencil.vue';
import Close from 'vue-material-design-icons/Close.vue';

Vue.component('mdi-cancel', Cancel);
Vue.component('mdi-check', Check);
Vue.component('mdi-plus', Plus);
Vue.component('mdi-pencil', Pencil);
Vue.component('mdi-delete', Delete);
Vue.component('mdi-close', Close);


import Polygon from 'vue-material-design-icons/ShapePolygonPlus.vue';
import MarkerPlus from 'vue-material-design-icons/MapMarkerPlus.vue';
import Layers from 'vue-material-design-icons/Layers.vue';

Vue.component('mdi-polygon', Polygon);
Vue.component('mdi-marker-plus', MarkerPlus);
Vue.component('mdi-layers', Layers);


import ArrowLeft from 'vue-material-design-icons/ArrowLeftThick.vue';
import ArrowRight from 'vue-material-design-icons/ArrowRightThick.vue';

Vue.component('mdi-arrow-left', ArrowLeft);
Vue.component('mdi-arrow-right', ArrowRight);


import Play from 'vue-material-design-icons/Play.vue';
import Stop from 'vue-material-design-icons/Stop.vue';


Vue.component('mdi-play', Play);
Vue.component('mdi-stop', Stop);

import Upload from 'vue-material-design-icons/Upload.vue';

Vue.component('mdi-upload', Upload);
