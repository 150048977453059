import HotMeta from '@/components/hotMeta/hotMeta';
import { metaMapping } from '@/helpers/hot';
import { IMeta } from '@/types/meta';

export default class Hot extends HotMeta {
    get upperDepthCol() {
        if (this.metaList) {
            return (
                this.metaList.findIndex((value: IMeta) => {
                    return value.name === 'upperDepth';
                }) + 1
            );
        }
    }

    get lowerDepthCol() {
        if (this.metaList) {
            return (
                this.metaList.findIndex((value: IMeta) => {
                    return value.name === 'lowerDepth';
                }) + 1
            );
        }
    }

    get settings() {
        const base: any = {
            colHeaders: ['Nr.'] as any[],
            columns: [
                {
                    readOnly: true,
                    type: 'numeric',
                    renderer: this.idRenderer,
                },
            ],
            minSpareRows: 1,
            afterChange: this.afterChange,
            afterValidate: this.afterValidate,
            beforeChange: this.beforeChange,
            columnSorting: true,
            stretchH: 'all',
            ...this.customSettings,
        };

        for (const column of this.metaList) {
            base.colHeaders.push(column.lable);

            base.columns.push(metaMapping(column, this.dependencies));
        }

        return base;
    }

    beforeChange = (
        changes: Array<[number, number, any, any]>,
        source: string
    ) => {
        if (changes && source !== 'populateFromArray' && this.upperDepthCol) {
            for (const [row, col, oldVal, val] of changes) {
                const meta = this.hot.getCellMeta(row, col) as Record<
                    string,
                    string
                >;

                for (const key of Object.keys(meta)) {
                    this.hot.removeCellMeta(row, col, key);
                }

                if (col === this.lowerDepthCol && typeof val === 'number') {
                    const upperDepth = this.hot.getDataAtCell(
                        row,
                        this.upperDepthCol
                    );

                    if (upperDepth !== null && upperDepth > val) {
                        this.hot.setCellMeta(
                            row,
                            col,
                            'end_depth_above_start',
                            'true'
                        );
                    }
                }
            }
        }
    };
}
