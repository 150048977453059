import * as Sentry from '@sentry/vue';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import Handsontable, { GridSettings } from 'handsontable';
import {
    beforePush,
    beforePut,
    fakeHeaderRenderer,
    metaMapping,
} from '@/helpers/hot';
import { IMeta } from '@/types/meta';
import imFetch from '@/api/imFetch';
import { analyticRenderer } from '@/helpers/renderers';

interface RowMeta {
    quantity: number | null;
    parameter: number | null;
    condition: number | null;
    uom: number | null;
}

interface StandaardVolgorde extends RowMeta {
    limit: number | null;
}

interface Analysis extends RowMeta {
    value: number;
    id: number;
}

interface SampleAnalysis extends Analysis {
    sample: number;
}

const rowMetaEqual = (one: RowMeta, two: RowMeta) => {
    return (
        one.uom === two.uom &&
        one.condition === two.condition &&
        one.parameter === two.parameter &&
        one.quantity === two.quantity
    );
};

@Component
export default class Hot extends Vue {
    @Prop()
    dependencies!: any[];

    @Prop()
    volgorde!: number | null;

    additionalCodes = ['ChemischeStof', 'Eenheid', 'Hoedanigheid', 'Grootheid'];

    @Action
    fetchCode: any;

    @Getter
    hasCode: any;

    hot!: Handsontable;

    invalidCells: any[] = [];

    sampleCols: { [index: number]: number } = {};

    rowMeta: Array<RowMeta> = [
        {
            condition: -1,
            quantity: -1,
            uom: -1,
            parameter: -1,
        },
    ];

    pending: any[] = [];

    quantityMeta: IMeta = {
        fieldtype: 'code',
        name: 'n/a',
        format: '',
        lable: 'Grootheid',
        relation: 'Grootheid',
        required: true,
    };

    sampleMeta: IMeta = {
        fieldtype: 'dependency',
        name: 'n/a',
        format: 'name',
        lable: 'Monster',
        relation: 'samples',
        required: false,
    };

    parameterMeta: IMeta = {
        fieldtype: 'code',
        name: 'n/a',
        format: '',
        lable: 'Chemische Stof',
        relation: 'ChemischeStof',
        required: false,
    };

    uomMeta: IMeta = {
        fieldtype: 'code',
        name: 'n/a',
        format: '',
        lable: 'Eenheid',
        relation: 'Eenheid',
        required: true,
    };

    conditionMeta: IMeta = {
        fieldtype: 'code',
        name: 'n/a',
        format: '',
        lable: 'Hoedanigheid',
        relation: 'Hoedanigheid',
        required: false,
    };

    quanityCells!: GridSettings;
    sampleCells!: GridSettings;
    substanceCells!: GridSettings;
    uomCells!: GridSettings;
    conditionCells!: GridSettings;

    get ready() {
        for (const code of this.additionalCodes) {
            if (!this.hasCode(code)) {
                this.fetchCode(code);
                return false;
            }
        }

        return true;
    }

    @Watch('ready')
    readyChanged(val: boolean) {
        if (val) {
            this.prepareCells();
            this.initHot();
        }
    }

    @Watch('volgorde')
    volgordeChanged(val: number | null) {
        if (val) {
            this.addVolgorde(val);
        }
    }

    mounted() {
        if (this.ready) {
            this.prepareCells();
            this.initHot();
        }
    }

    beforeDestroy() {
        this.destroy();
    }

    destroy() {
        if (this.hot) {
            this.hot.destroy();
        }
    }

    clean() {
        this.invalidCells = [];

        this.rowMeta = [
            {
                condition: -1,
                parameter: -1,
                quantity: -1,
                uom: -1,
            },
        ];

        this.sampleCols = {};
        this.pending = [];

        this.initHot();
    }

    initHot() {
        this.destroy();

        this.invalidCells = [];

        const container: Element = this.$refs.container as Element;

        this.hot = new Handsontable(container, this.getSettings());
    }

    getSettings() {
        const fakeHeaders = [
            'Grootheid',
            'Chemische Stof',
            'Eenheid',
            'Hoedanigheid',
            '<',
        ];

        return {
            fixedRowsTop: 1,
            fixedColumnsLeft: 5,
            colHeaders: false,
            minSpareCols: 1,
            minSpareRows: 1,
            cells: this.cells,
            data: [
                fakeHeaders,
                // ...this.initRows(),
            ],
            afterValidate: this.afterValidate,
            afterChange: this.afterChange,
            beforeChange: this.beforeChange,
            beforePaste: this.beforePaste,
        } as Handsontable.DefaultSettings;
    }

    prepareCells() {
        this.quanityCells = metaMapping(this.quantityMeta);
        this.sampleCells = metaMapping(this.sampleMeta, this.dependencies);
        this.substanceCells = metaMapping(this.parameterMeta);
        this.uomCells = metaMapping(this.uomMeta);
        this.conditionCells = metaMapping(this.conditionMeta);
    }

    cells(row?: number, column?: number): GridSettings {
        if (row !== undefined && column !== undefined) {
            if (row === 0) {
                if (column < 5 || this.sampleCols[column]) {
                    return {
                        renderer: fakeHeaderRenderer,
                        editor: false,
                    };
                } else {
                    return this.sampleCells;
                }
            } else {
                switch (column) {
                    case 0:
                        return this.quanityCells;
                    case 1:
                        return this.substanceCells;
                    case 2:
                        return this.uomCells;
                    case 3:
                        return this.conditionCells;
                    case 4:
                        return {
                            type: 'numeric',
                            numericFormat: {
                                pattern: '0.000',
                                culture: 'nl-NL',
                            },
                            editor: false,
                        };
                    default:
                        return {
                            type: 'numeric',
                            numericFormat: {
                                pattern: '0.000',
                                culture: 'nl-NL',
                            },
                            renderer: analyticRenderer(
                                Handsontable.renderers.NumericRenderer
                            ),
                        };
                }
            }
        }

        return {};
    }

    afterValidate(isValid: boolean, value: any, row: number, col: number) {
        if (!isValid) {
            if (!this.invalidCells[row]) {
                this.invalidCells[row] = [];
            }

            if (!this.invalidCells[row].includes(col)) {
                this.invalidCells[row].push(col);
            }
        } else {
            if (
                this.invalidCells[row] &&
                this.invalidCells[row].includes(col)
            ) {
                this.invalidCells[row].splice(
                    this.invalidCells[row].indexOf(col),
                    1
                );
            }
        }
    }

    isValid() {
        for (const col of this.invalidCells) {
            if (col > 4 && col.length > 0) {
                return false;
            }
        }

        return true;
    }

    cellIsValid(row: number, col: number) {
        if (this.invalidCells[row]) {
            if (this.invalidCells[row].includes(col)) {
                return false;
            }
        }

        return true;
    }

    beforeChange(changes: Array<[number, number, any, any]>, source: string) {
        if (changes) {
            for (const [row, col, oldVal, rawVal] of changes) {
                let value: number | null;
                if (this.cellIsValid(row, col)) {
                    value = rawVal;
                } else {
                    value = null;
                }

                // Track meta
                if (!['insertSample', 'populateFromArray'].includes(source)) {
                    if (row > 0 && col < 5) {
                        while (this.rowMeta.length <= row) {
                            this.rowMeta.push({
                                quantity: null,
                                parameter: null,
                                uom: null,
                                condition: null,
                            });
                        }

                        switch (col) {
                            case 0:
                                this.rowMeta[row].quantity = beforePut(
                                    value,
                                    this.quantityMeta,
                                    this.dependencies
                                );
                                break;
                            case 1:
                                this.rowMeta[row].parameter = beforePut(
                                    value,
                                    this.parameterMeta,
                                    this.dependencies
                                );
                                this.checkDoubleSubstances();
                                break;
                            case 2:
                                this.rowMeta[row].uom = beforePut(
                                    value,
                                    this.uomMeta,
                                    this.dependencies
                                );
                                break;
                            case 3:
                                this.rowMeta[row].condition = beforePut(
                                    value,
                                    this.conditionMeta,
                                    this.dependencies
                                );
                        }

                        // Set all existing values on this row to mutated
                        for (const sampleCol of Object.keys(this.sampleCols)) {
                            this.hot.setCellMeta(
                                row,
                                Number(sampleCol),
                                'mutated',
                                'true'
                            );
                        }
                    }

                    // Track single mutations
                    if (source !== 'insertSample') {
                        if (row > 0 && col > 4) {
                            this.hot.setCellMeta(row, col, 'mutated', 'true');
                        }
                    }
                }
            }
        }
    }

    afterChange(changes: Array<[number, number, any, any]>, source: string) {
        if (changes) {
            for (const [row, col, oldVal, val] of changes) {
                if (this.cellIsValid(row, col)) {
                    if (row === 0) {
                        this.sampleCols[col] = beforePut(
                            val,
                            this.sampleMeta,
                            this.dependencies
                        );

                        if (val) {
                            this.insertSample(this.sampleCols[col]);
                        }
                    }
                }
            }

            if (!['insertSample', 'populateFromArray'].includes(source)) {
                this.$emit('pending', true);
            }
        }

        this.$emit('valid', this.isValid());
    }

    beforePaste(data: any[][], coords: any[]) {
        const newData: number[][] = [];

        // Alle niet geldige data weggooien
        if (coords[0].startCol > 3 && coords[0].startRow > 0) {
            const lowenThanRegex = /< /g;
            const isNumberRegex = /^(-|<)?\d+(\.|,)?\d*$/;

            let newRow: number[];
            let split: any[];

            for (const pasteRow of data) {
                newRow = [];

                for (const item of pasteRow) {
                    // Splitsen in kolommen
                    split = item.trim().replace(lowenThanRegex, '<').split(' ');

                    for (const part of split) {
                        if (isNumberRegex.test(part)) {
                            newRow.push(this.parseNumber(part));
                        }
                    }
                }

                if (newRow.length > 0) {
                    newData.push(newRow);
                }
            }

            // Originele data vervangen door uitgekamde data
            data.splice(0);
            data.push(...newData);
        }
    }

    parseNumber(input: string | number) {
        if (typeof input === 'string') {
            if (input.substr(0, 2) === '< ') {
                input = '-' + input.substr(2);
            } else if (input.substr(0, 1) === '<') {
                input = '-' + input.substr(1);
            }

            input = input.replace(',', '.');

            return Number(input);
        }

        return input;
    }

    emitOperations() {
        const operations = {
            put: [] as any[],
            post: [] as any[],
            delete: [] as any[],
            total: 0,
        };

        const cells: [number, number][] = [];

        // Sorry
        for (const [row, rowMeta] of Object.entries(this.rowMeta).map(
            ([row, rowMeta]) => [Number(row), rowMeta] as [number, RowMeta]
        )) {
            if (
                rowMeta.quantity &&
                rowMeta.quantity >= 0 &&
                rowMeta.uom &&
                rowMeta.uom >= 0
            ) {
                for (const [col, sample] of Object.entries(this.sampleCols).map(
                    ([col, sample]) => [Number(col), sample]
                )) {
                    const meta: any = this.hot.getCellMeta(row, col);

                    if (meta.mutated) {
                        const value: null | number | string =
                            this.hot.getDataAtCell(row, col);
                        console.log(rowMeta, meta, value);

                        // Geldige celwaarde en een ID
                        if (meta.id && typeof value === 'number') {
                            // We gaan een waarde overschrijven
                            operations.put.push({
                                ...rowMeta,
                                id: Number(meta.id),
                                value,
                            });
                            // ID en de celwaarde is geen getal (dus null of lege string)
                        } else if (meta.id) {
                            // We gaan het resultaat met dit ID verwijderen
                            operations.delete.push(Number(meta.id));
                            // Geen ID en de celwaarde is geldig
                        } else if (typeof value === 'number') {
                            // We gaan een nieuw resultaat opslaan
                            operations.post.push({
                                ...rowMeta,
                                sample,
                                value,
                            });
                        } else {
                            Sentry;
                            console.debug(
                                `Ongeldig resultaat? ${meta.id}, ${value}`
                            );
                            Sentry.captureException(
                                new Error(
                                    `Ongeldig resultaat? ${meta.id}, ${value}`
                                )
                            );
                            continue;
                        }

                        cells.push([col, row]);
                    }
                }
            }
        }

        return { operations, cells };
    }

    operationsEmitted(
        feedback: { insert: SampleAnalysis[]; remove: number[] },
        cellsAffected: [number, number][]
    ) {
        const insertSamples = feedback.insert.reduce((acc, curr) => {
            if (!acc[curr.sample]) {
                acc[curr.sample] = [curr];
            } else {
                acc[curr.sample].push(curr);
            }

            return acc;
        }, {} as Record<number, SampleAnalysis[]>);

        for (const [sample, analyses] of Object.entries(insertSamples)) {
            this.insertAnalyses(Number(sample), analyses);
        }

        for (const [col, row] of cellsAffected) {
            this.hot.removeCellMeta(row, col, 'mutated');

            if (feedback.remove.length > 0) {
                const id = Number((<any>this.hot.getCellMeta(row, col)).id);

                if (feedback.remove.includes(id)) {
                    this.hot.removeCellMeta(row, col, 'id');
                    feedback.remove.splice(feedback.remove.indexOf(id), 1);
                }
            }
        }

        if (feedback.remove.length > 0) {
            throw new Error(
                'Verwijderde cellen konden niet worden opgeschoond.'
            );
        }

        this.hot.render();
    }

    async addVolgorde(id: number) {
        const items: StandaardVolgorde[] = await imFetch(
            `/frontend/standaardvolgordes/${id}/items/`
        );

        console.log(items);

        const rows: StandaardVolgorde[][] = [];

        for (const item of items) {
            if (
                this.rowMeta.findIndex((value) => {
                    return rowMetaEqual(value, item);
                }) === -1
            ) {
                this.rowMeta.push({
                    quantity: item.quantity,
                    uom: item.uom,
                    condition: item.condition,
                    parameter: item.parameter,
                });

                rows.push([
                    beforePush(item.quantity, this.quantityMeta),
                    beforePush(item.parameter, this.parameterMeta),
                    beforePush(item.uom, this.uomMeta),
                    beforePush(item.condition, this.conditionMeta),
                    item.limit,
                ]);
            }
        }

        if (rows.length > 0) {
            this.hot.populateFromArray(this.hot.countRows() - 1, 0, rows);
        }
    }

    insertAnalyses(sample: number, analyses: Analysis[]) {
        const sampleCol = Object.entries(this.sampleCols).find(
            ([col, id]) => id == sample
        );

        if (!sampleCol) {
            throw new Error(`Geen kolom toegewezen aan monster ${sample}`);
        }

        const col = Number(sampleCol[0]);

        const changes: Array<[number, number, number]> = [];
        const toNewRow: Analysis[] = [];
        const cellId: Array<[number, number, number]> = [];

        for (const analysis of analyses) {
            const row = this.rowMeta.findIndex((value) => {
                return rowMetaEqual(value, analysis);
            });

            if (row > 0) {
                changes.push([row, col, analysis.value]);
                cellId.push([row, col, analysis.id]);
            } else {
                toNewRow.push(analysis);
            }
        }

        for (const analysis of toNewRow) {
            const row = this.rowMeta.length;

            this.rowMeta.push({
                quantity: analysis.quantity,
                uom: analysis.uom,
                condition: analysis.condition,
                parameter: analysis.parameter,
            });

            changes.push(
                [row, col, analysis.value],
                [row, 0, beforePush(analysis.quantity, this.quantityMeta)],
                [row, 1, beforePush(analysis.parameter, this.parameterMeta)],
                [row, 2, beforePush(analysis.uom, this.uomMeta)],
                [row, 3, beforePush(analysis.condition, this.conditionMeta)]
            );

            cellId.push([row, col, analysis.id]);
        }

        if (changes.length > 0) {
            this.hot.setDataAtCell(
                changes as any,
                undefined as any,
                undefined,
                'insertSample'
            );
        }

        for (const [row, col, id] of cellId) {
            this.hot.removeCellMeta(row, col, 'mutated');
            this.hot.setCellMeta(row, col, 'id', String(id));
        }

        this.checkDoubleSubstances();
    }

    checkDoubleSubstances() {
        const substances: number[] = [];

        for (const [row, meta] of this.rowMeta.entries()) {
            if (meta.parameter && substances.includes(meta.parameter)) {
                this.hot.setCellMeta(row, 1, 'duplicatedSubstance', 'true');
            } else {
                this.hot.removeCellMeta(row, 1, 'duplicatedSubstance');
            }

            if (meta.parameter) {
                substances.push(meta.parameter);
            }
        }
    }

    async insertSample(sample: number) {
        try {
            const analyses: Analysis[] = await imFetch(
                `/bodeminformatie/samples/${sample}/analyses/`
            );

            this.insertAnalyses(sample, analyses);
        } catch (e) {
            throw new Error('Monster kon niet worden ingeladen');
        }
    }
}
