import { Component, Vue } from 'vue-property-decorator';

import Navbar from '@/components/navbar/navbar.vue';
import Tray from '@/components/tray/tray.vue';
import Import from '@/components/import/import.vue';

@Component({
    components: {
        Navbar,
        Tray,
        Import,
    },
})
export default class Container extends Vue {
    
}
