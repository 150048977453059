import { render, staticRenderFns } from "./general.vue?vue&type=template&id=368d1b61&scoped=true&"
import script from "./general.ts?vue&type=script&lang=ts&"
export * from "./general.ts?vue&type=script&lang=ts&"
import style0 from "./general.vue?vue&type=style&index=0&id=368d1b61&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368d1b61",
  null
  
)

export default component.exports