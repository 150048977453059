import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Hot from '@/components/hot/hot.vue';
import Handsontable from 'handsontable';

import { Action, Getter } from 'vuex-class';

import { beforePut, metaMapping, transformInstance } from '@/helpers/hot';

@Component({
    components: {
        Hot,
    },
})
export default class HotMeta extends Vue {
    @Prop()
    value: any;

    /**
     * Name of the metadata table to use
     */
    @Prop()
    meta!: string;

    /**
     * Instances to show as row in the table
     */
    @Prop()
    instances!: any[];

    /**
     * Dependencies that can be used to fill a dropdown cell
     */
    @Prop()
    dependencies!: any[] | null;

    /**
     * A list of instances that should be appended to the table
     */
    @Prop()
    toAdd!: any[] | null;

    @Prop({ default: () => Object() })
    customSettings!: Record<string, any>;

    @Action
    fetchMeta: any;

    @Action
    fetchCode: any;

    @Getter
    hasCode: any;

    get settings() {
        const base: any = {
            colHeaders: ['Nr.'] as any[],
            columns: [
                {
                    readOnly: true,
                    type: 'numeric',
                    renderer: this.idRenderer,
                },
            ],
            minSpareRows: 1,
            afterChange: this.afterChange,
            afterValidate: this.afterValidate,
            columnSorting: true,
            stretchH: 'all',
            ...this.customSettings,
            // preventOverflow: 'horizontal',op
        };

        for (const column of this.metaList) {
            base.colHeaders.push(column.lable);

            base.columns.push(metaMapping(column, this.dependencies));
        }

        return base;
    }

    tableData: any[] | null = null;

    mutatedRows: number[] = [];
    annotations: Record<string, number[]> = {
        added: [],
        updated: [],
        removed: [],
    };

    invalidCells: any[] = [];

    get ready() {
        if (this.metaList) {
            for (const item of this.metaList) {
                if (
                    item.fieldtype === 'code' &&
                    item.relation &&
                    !this.hasCode(item.relation)
                ) {
                    this.fetchCode(item.relation);
                    return false;
                }
            }

            return true;
        } else {
            return false;
        }
    }

    valid = false;

    get metaList(): any[] {
        return this.$store.state.meta.metas[this.meta];
    }

    get hot() {
        if (this.$refs.hot) {
            return (this.$refs.hot as any).hot;
        }

        return undefined;
        // return (this.$refs.hot as any)?.hot;
    }

    @Watch('valid')
    validChanged(val: boolean) {
        this.$emit('valid', val);
    }

    @Watch('ready')
    readyChanged() {
        if (this.ready) {
            this.initRows();
        }
    }

    @Watch('instances')
    instancesChanged() {
        this.resetTable();
        this.initRows();
    }

    @Watch('value')
    valueChanged(val: any, oldVal: any) {
        if (!val && oldVal) {
            this.mutatedRows.splice(0);
            this.hot.render();
        }
    }

    /**
     * When the toAdd prop changes it will contain a list of instances
     * to append tot the table.
     * @param toAdd list of instance objects to add
     */
    @Watch('toAdd')
    toAddChanged(toAdd: any[]) {
        if (toAdd) {
            const hot: Handsontable = (this.$refs.hot as any).hot;

            const parsed: any[] = [];

            for (const pending of toAdd) {
                parsed.push(transformInstance(pending, this.metaList));
            }

            // Append below the current data
            hot.populateFromArray(hot.countRows() - 1, 0, parsed);
        }
    }

    mounted() {
        this.init();
    }

    init() {
        if (!this.metaList) {
            // No metadata has been loaded for this object type
            this.fetchMeta(this.meta);
        } else {
            // Metadata is present, we can check if the form is ready to be displayed
            this.readyChanged();
        }
    }

    resetTable() {
        this.mutatedRows.splice(0);
        this.invalidCells.splice(0);
        this.$emit('input', null);
    }

    idRenderer: Handsontable.renderers.Text = (
        hotInstance,
        td,
        row,
        column,
        prop,
        value,
        cellProperties
    ) => {
        Handsontable.renderers.TextRenderer(
            hotInstance,
            td,
            row,
            column,
            prop,
            value,
            cellProperties
        );

        if (this.mutatedRows.includes(row)) {
            if (this.annotations.added.includes(row)) {
                td.style.backgroundColor = '#c7ffdf';
            } else if (this.annotations.updated.includes(row)) {
                td.style.backgroundColor = '#fff5dc';
            } else if (this.annotations.removed.includes(row)) {
                td.style.backgroundColor = '#ffc8c5';
            }
        }

        return td;
    };

    /**
     * Fill the hot with the inital list of instances
     */
    initRows() {
        if (this.metaList) {
            const newData: any[] = [];

            for (const instance of this.instances) {
                newData.push(
                    transformInstance(
                        instance,
                        this.metaList,
                        this.dependencies
                    )
                );
            }

            this.tableData = newData;
        }
    }

    isValid() {
        for (const [row, col] of this.invalidCells.entries()) {
            if (col && !this.isEmptyRowByNumber(row) && col.length > 0) {
                return false;
            }
        }

        return true;
    }

    isEmptyRow(row: any[]) {
        return (
            row.slice(1).filter((value) => {
                return value !== '' && value !== null;
            }).length === 0
        );
    }

    isEmptyRowByNumber(row: number) {
        const raw: any[] = (this.$refs.hot as any).hot.getDataAtRow(row);

        return this.isEmptyRow(raw);
    }

    afterValidate(isValid: boolean, value: any, row: number, col: number) {
        if (!isValid) {
            if (!this.invalidCells[row]) {
                this.invalidCells[row] = [];
            }

            if (!this.invalidCells[row].includes(col)) {
                this.invalidCells[row].push(col);
            }
        } else {
            if (
                this.invalidCells[row] &&
                this.invalidCells[row].includes(col)
            ) {
                this.invalidCells[row].splice(
                    this.invalidCells[row].indexOf(col),
                    1
                );
            }
        }
    }

    afterChange(
        changes: Array<[number, string | number, any, any]>,
        source: string
    ) {
        if (changes) {
            this.hot.validateRows(changes.map((value) => value[0]));

            for (const [row] of changes) {
                this.hot.validateRows([row]);
                if (!this.mutatedRows.includes(row)) {
                    this.mutatedRows.push(row);
                }
            }

            this.valid = this.isValid();

            if (this.valid) {
                this.emitOperations();
            }

            this.hot.render();
        }
    }

    /**
     * Create a list of operations that will sync the database with the table
     */
    emitOperations() {
        const operations = {
            put: [] as any[],
            post: [] as any[],
            delete: [] as any[],
            total: 0,
        };

        const hot: any = (this.$refs.hot as any).hot;

        let reconstructed: any;
        let empty: boolean;

        const addedRows: number[] = [];
        const updatedRows: number[] = [];
        const removedRows: number[] = [];

        // Loop through all the mutated rows, well create an operation for
        // each of them (if valid data is present)
        for (const rowNum of this.mutatedRows) {
            const raw: any[] = hot.getDataAtRow(rowNum);

            // Row transformed to api-object
            reconstructed = {};

            for (const [i, meta] of this.metaList.entries()) {
                // Shift index by 1 to account for the id prefix column
                reconstructed[meta.name] = beforePut(
                    raw[i + 1],
                    meta,
                    this.dependencies
                );
            }

            // Check if a row can be regarded as emtpy
            empty =
                raw.slice(1).filter((value) => {
                    return value !== '' && value !== null;
                }).length === 0;

            if (raw[0]) {
                if (empty) {
                    // This row has an id, but is empty, well delete the instance
                    operations.delete.push(raw[0]);
                    removedRows.push(rowNum);
                } else {
                    reconstructed.id = raw[0];
                    operations.put.push(reconstructed);
                    updatedRows.push(rowNum);
                }
            } else if (!empty) {
                // This row has data, but doesn't yet have an id, we'll post it
                operations.post.push(reconstructed);
                addedRows.push(rowNum);
            }

            operations.total += 1;
        }

        this.annotations.added = addedRows;
        this.annotations.updated = updatedRows;
        this.annotations.removed = removedRows;

        this.$emit('input', operations);
    }
}
