import { Component, Vue } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

@Component
export default class Navbar extends Vue {
    @State((state) => state.api.user)
    user!: any;

    @Action('logout')
    logoutAction!: any;

    logout() {
        this.logoutAction();
        this.$router.push({
            name: 'login',
        });
    }
}
