import { Component, Vue, Prop } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';

import Editor from './editor/editor.vue';
import NewForm from './newForm/newForm.vue';

@Component({
    components: {
        Editor,
        NewForm,
    },
})
export default class Standaardvolgordes extends Vue {
    @Prop()
    id!: number;

    volgordes: any[] = [];

    mounted() {
        this.loadData();
    }

    async loadData() {
        this.volgordes = await imFetch('/frontend/standaardvolgordes/');
    }
}
