import { Module } from 'vuex';
import imFetch from '@/api/imFetch';
import Vue from 'vue';


const moduleState = {
    codes: {} as { [index: string]: any[] },
    choices: {} as { [index: string]: any[] },
    fetching: [] as string[],
};

export default {
    state: moduleState,
    getters: {
        hasCode: (state) => (name: string) => {
            return Boolean(state.codes[name]);
        },
        hasChoice: (state) => (path: string) => {
            return Boolean(state.choices[path]);
        },
        flatCode: (state) => (name: string) => {
            return state.codes[name].map((value) => {
                return value.value;
            });
        },
        flatChoice: (state) => (path: string) => {
            return state.choices[path].map((value) => {
                return value.value;
            });
        },
        codePk: (state) => (value: string, code: string) => {
            return state.codes[code].find((i: any) => {
                return i.value === value;
            }).id || null;
        },
        pkValue: (state) => (pk: string, code: string) => {
            if (!state.codes[code]) {
                throw new Error(`Domeinlijst voor ${code} was nog niet ingeladen!`);
            }

            const pair = state.codes[code].find((i: any) => {
                return i.id === pk;
            });

            return pair ? pair.value : 'Error';
        },
    },
    mutations: {
        insertCode(state, { name, contents }) {
            Vue.set(state.codes, name, contents);
        },
        insertChoice(state, { path, contents }) {
            Vue.set(state.choices, path, contents);
        },
        startCodeFetch(state, name: string) {
            if (!state.fetching.includes(name)) {
                state.fetching.push(name);
            }
        },
        endCodeFetch(state, name: string) {
            if (state.fetching.includes(name)) {
                state.fetching.splice(state.fetching.indexOf(name), 1);
            }
        },
    },
    actions: {
        async fetchCode({ state, commit }, name: string) {
            if (!state.fetching.includes(name)) {
                commit('startCodeFetch', name);

                try {
                    const request = await imFetch(`/codes/${name}/`);

                    commit('insertCode', {
                        name,
                        contents: request,
                    });
                } catch (e) {
                }
            }
        },
        async fetchChoice({ state, commit }, path: string) {
            if (!state.fetching.includes(path)) {
                commit('startCodeFetch', path);

                try {
                    const request = await imFetch(path);

                    commit('insertChoice', {
                        path,
                        contents: request,
                    });
                } catch (e) {
                }
            }
        },
    },
} as Module<typeof moduleState, any>;
